import { OutcomeType, PlanType } from "@slrc/calculator-service/graphql-schema";
import { flatten } from "ramda";
export const outcomeTypeToReadableOutcomeTypeMap = {
    [OutcomeType.PAID_OFF]: "paid off",
    [OutcomeType.WRITTEN_OFF]: "written off"
};
export const planTypeToReadablePlanTypeMap = {
    [PlanType.UK_PLAN1]: "Plan 1",
    [PlanType.UK_PLAN2]: "Plan 2",
    [PlanType.UK_PLAN3]: "Postgraduate",
    [PlanType.UK_PLAN4]: "Plan 4",
    [PlanType.UK_PLAN5]: "Plan 5"
};
export const planTypeToLongerReadablePlanTypeMap = {
    [PlanType.UK_PLAN1]: "Plan 1 (before Sep 2012)",
    [PlanType.UK_PLAN2]: "Plan 2 (Sep 2012 - Jul 2023)",
    [PlanType.UK_PLAN3]: "Postgraduate",
    [PlanType.UK_PLAN4]: "Plan 4 (Scottish)",
    [PlanType.UK_PLAN5]: "Plan 5 (after Aug 2023)"
};
// TODO: is this best place for this func?
export const yearsToMonths = (years)=>flatten(years.map((param)=>{
        let { summary: { income  } , months  } = param;
        return months.map((m)=>{
            return {
                income,
                ...m.summary
            };
        });
    }));
