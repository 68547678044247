export const isDifferenceMore = (currentDebt, lifetimeMandatory, lifetimeVoluntary, useRealValues)=>{
    const difference = currentDebt - (lifetimeMandatory[useRealValues ? "real" : "nominal"] + lifetimeVoluntary[useRealValues ? "real" : "nominal"]);
    const isMore = difference <= 0;
    return {
        isMore,
        difference: Math.abs(difference)
    };
};
export const checkIfVoluntaryPaymentsAddedInCalculateInput = (voluntaryCalculateInput)=>{
    return voluntaryCalculateInput.loans.some((l)=>l.extraMonthlyRepayments !== undefined && l.extraMonthlyRepayments.length > 0);
};
export const checkIfVoluntaryPaymentsAddedInLoanInput = (voluntaryLoanInput)=>{
    return voluntaryLoanInput.extraMonthlyRepayments !== undefined && voluntaryLoanInput.extraMonthlyRepayments.length > 0;
};
