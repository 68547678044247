import * as yup from "yup";
import { MAX_MONETARY_AMOUNT, MAX_MONETARY_AMOUNT_SMALL } from "@slrc/calculator-service/constants";
export const monetaryAmountYupSchema = yup.number().min(0, (param)=>{
    let { path , min  } = param;
    return "".concat(path, " cannot be less than ").concat(min);
}).max(MAX_MONETARY_AMOUNT, (param)=>{
    let { path , max  } = param;
    return "".concat(path, " cannot be more than ").concat(max);
}).required("Required").typeError("Required");
export const monetaryAmountSmallYupSchema = yup.number().min(0, (param)=>{
    let { path , min  } = param;
    return "".concat(path, " cannot be less than ").concat(min);
}).max(MAX_MONETARY_AMOUNT_SMALL, (param)=>{
    let { path , max  } = param;
    return "".concat(path, " cannot be more than ").concat(max);
}).required("Required").typeError("Required");
