import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    const data = _tagged_template_literal([
        "\n  position: relative;\n  display: flex;\n  justify-content: center;\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
function _templateObject1() {
    const data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n  align-items: baseline;\n  background-color: var(--slrc-colour-slate-50);\n  padding: 4px;\n  border-radius: 6px;\n  list-style: none;\n  margin: 0;\n  gap: 8px;\n  border: 1px solid var(--slrc-colour-grey-200);\n"
    ]);
    _templateObject1 = function() {
        return data;
    };
    return data;
}
function _templateObject2() {
    const data = _tagged_template_literal([
        ""
    ]);
    _templateObject2 = function() {
        return data;
    };
    return data;
}
function _templateObject3() {
    const data = _tagged_template_literal([
        "\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  top: 100%;\n  right: 0;\n  perspective: 2000px;\n"
    ]);
    _templateObject3 = function() {
        return data;
    };
    return data;
}
function _templateObject4() {
    const data = _tagged_template_literal([
        "\n  position: relative;\n  transform-origin: top center;\n  margin-top: 10px;\n  width: 100%;\n  min-width: 300px;\n  background-color: var(--slrc-colour-grey-50);\n  border-radius: 6px;\n  overflow: hidden;\n  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,\n    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;\n  height: var(--radix-navigation-menu-viewport-height);\n  transition: width, height, 300ms ease;\n\n  &[data-state='open'] {\n    animation: scaleIn 200ms ease;\n  }\n  &[data-state='closed'] {\n    animation: scaleOut 200ms ease;\n  }\n"
    ]);
    _templateObject4 = function() {
        return data;
    };
    return data;
}
function _templateObject5() {
    const data = _tagged_template_literal([
        "\n  /* padding: 8px 12px;\n  outline: none;\n  user-select: none;\n  font-weight: 500;\n  line-height: 1;\n  border-radius: 4px;\n  font-size: 15px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 2px; */\n"
    ]);
    _templateObject5 = function() {
        return data;
    };
    return data;
}
function _templateObject6() {
    const data = _tagged_template_literal([
        "\n--navigation-menu-shadow-color: 0deg 0% 63%\n  position: absolute;\n  min-width: 300px;\n  width: 100%;\n  animation-duration: 250ms;\n  animation-timing-function: ease;\n  padding: 16px;\n  border: 1px solid var(--slrc-colour-grey-200);\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  box-shadow: 0.2px 0.5px 0.6px hsl(var(--navigation-menu-shadow-color) / 0.29),\n    0.4px 1.3px 1.5px -0.6px hsl(var(--navigation-menu-shadow-color) / 0.29),\n    0.8px 2.6px 3.1px -1.2px hsl(var(--navigation-menu-shadow-color) / 0.29),\n    1.7px 5.2px 6.2px -1.9px hsl(var(--navigation-menu-shadow-color) / 0.29),\n    3.2px 10px 11.8px -2.5px hsl(var(--navigation-menu-shadow-color) / 0.29);\n\n  &[data-motion='from-start'] {\n    animation-name: enterFromLeft;\n  }\n  &[data-motion='from-end'] {\n    animation-name: enterFromRight;\n  }\n  &[data-motion='to-start'] {\n    animation-name: exitToLeft;\n  }\n  &[data-motion='to-end'] {\n    animation-name: exitToRight;\n  }\n"
    ]);
    _templateObject6 = function() {
        return data;
    };
    return data;
}
function _templateObject7() {
    const data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: flex-end;\n  justify-content: center;\n  height: 10px;\n  top: 100%;\n  overflow: hidden;\n  transition: width, transform 250ms ease;\n\n  &[data-state='visible'] {\n    animation: fadeIn 200ms ease;\n  }\n  &[data-state='hidden'] {\n    animation: fadeOut 200ms ease;\n  }\n"
    ]);
    _templateObject7 = function() {
        return data;
    };
    return data;
}
function _templateObject8() {
    const data = _tagged_template_literal([
        "\n  position: relative;\n  top: 70%;\n  width: 10px;\n  height: 10px;\n  transform: rotate(45deg);\n  border-top-left-radius: 2px;\n  background-color: white;\n"
    ]);
    _templateObject8 = function() {
        return data;
    };
    return data;
}
function _templateObject9() {
    const data = _tagged_template_literal([
        "\n  position: relative;\n  top: 1px;\n  transition: transform 250ms ease;\n"
    ]);
    _templateObject9 = function() {
        return data;
    };
    return data;
}
function _templateObject10() {
    const data = _tagged_template_literal([
        "\n  [data-state='open'] > ",
        " {\n    transform: rotate(-180deg);\n  }\n\n  @keyframes enterFromRight {\n    from {\n      opacity: 0;\n      transform: translateX(200px);\n    }\n    to {\n      opacity: 1;\n      transform: translateX(0);\n    }\n  }\n\n  @keyframes enterFromLeft {\n    from {\n      opacity: 0;\n      transform: translateX(-200px);\n    }\n    to {\n      opacity: 1;\n      transform: translateX(0);\n    }\n  }\n\n  @keyframes exitToRight {\n    from {\n      opacity: 1;\n      transform: translateX(0);\n    }\n    to {\n      opacity: 0;\n      transform: translateX(200px);\n    }\n  }\n\n  @keyframes exitToLeft {\n    from {\n      opacity: 1;\n      transform: translateX(0);\n    }\n    to {\n      opacity: 0;\n      transform: translateX(-200px);\n    }\n  }\n\n  @keyframes scaleIn {\n    from {\n      opacity: 0;\n      transform: rotateX(-30deg) scale(0.9);\n    }\n    to {\n      opacity: 1;\n      transform: rotateX(0deg) scale(1);\n    }\n  }\n\n  @keyframes scaleOut {\n    from {\n      opacity: 1;\n      transform: rotateX(0deg) scale(1);\n    }\n    to {\n      opacity: 0;\n      transform: rotateX(-10deg) scale(0.95);\n    }\n  }\n\n  @keyframes fadeIn {\n    from {\n      opacity: 0;\n    }\n    to {\n      opacity: 1;\n    }\n  }\n\n  @keyframes fadeOut {\n    from {\n      opacity: 1;\n    }\n    to {\n      opacity: 0;\n    }\n  }\n"
    ]);
    _templateObject10 = function() {
        return data;
    };
    return data;
}
import * as RadixNavigationMenu from "@radix-ui/react-navigation-menu";
import { BiChevronDown } from "react-icons/bi";
import styled from "styled-components";
export const Root = styled(RadixNavigationMenu.Root).withConfig({
    displayName: "style__Root",
    componentId: "sc-9221d7ff-0"
})(_templateObject());
export const List = styled(RadixNavigationMenu.List).withConfig({
    displayName: "style__List",
    componentId: "sc-9221d7ff-1"
})(_templateObject1());
export const Item = styled(RadixNavigationMenu.Item).withConfig({
    displayName: "style__Item",
    componentId: "sc-9221d7ff-2"
})(_templateObject2());
export const ViewportWrapper = styled.div.withConfig({
    displayName: "style__ViewportWrapper",
    componentId: "sc-9221d7ff-3"
})(_templateObject3());
export const Viewport = styled(RadixNavigationMenu.Viewport).withConfig({
    displayName: "style__Viewport",
    componentId: "sc-9221d7ff-4"
})(_templateObject4());
export const Trigger = styled(RadixNavigationMenu.Trigger).withConfig({
    displayName: "style__Trigger",
    componentId: "sc-9221d7ff-5"
})(_templateObject5());
// export const Link = styled(RadixNavigationMenu.Link)`
//   padding: 8px 12px;
//   outline: none;
//   user-select: none;
//   font-weight: 500;
//   line-height: 1;
//   border-radius: 4px;
//   font-size: 15px;
//   display: block;
//   text-decoration: none;
//   font-size: 15px;
//   line-height: 1;
// `;
export const Content = styled(RadixNavigationMenu.Content).withConfig({
    displayName: "style__Content",
    componentId: "sc-9221d7ff-6"
})(_templateObject6());
export const Indicator = styled(RadixNavigationMenu.Indicator).withConfig({
    displayName: "style__Indicator",
    componentId: "sc-9221d7ff-7"
})(_templateObject7());
export const IndicatorArrow = styled.div.withConfig({
    displayName: "style__IndicatorArrow",
    componentId: "sc-9221d7ff-8"
})(_templateObject8());
// TODO: fix squashing
export const ArrowDown = styled(BiChevronDown).withConfig({
    displayName: "style__ArrowDown",
    componentId: "sc-9221d7ff-9"
})(_templateObject9());
export const Wrapper = styled.div.withConfig({
    displayName: "style__Wrapper",
    componentId: "sc-9221d7ff-10"
})(_templateObject10(), ArrowDown);
