import { AiFillTwitterCircle, AiFillRedditCircle, AiFillInstagram } from "react-icons/ai";
import { MdFacebook } from "react-icons/md";
export const socialMedia = [
    {
        link: "https://twitter.com/YourSLRC",
        icon: AiFillTwitterCircle
    },
    {
        link: "https://www.reddit.com/user/YourSLRC",
        icon: AiFillRedditCircle
    },
    {
        link: "https://www.instagram.com/yourslrc",
        icon: AiFillInstagram
    },
    {
        link: "https://www.facebook.com/Your-Student-Loan-Repayment-Calculator-101344239307647",
        icon: MdFacebook
    }
];
