import * as yup from "yup";
import { dateAsYearAndMonthYupSchema } from "./date-input";
import { monetaryAmountSmallYupSchema, monetaryAmountYupSchema } from "./monetary-input";
const extraMonthlyRepaymentYupSchema = yup.object({
    from: dateAsYearAndMonthYupSchema,
    to: dateAsYearAndMonthYupSchema,
    amount: monetaryAmountSmallYupSchema
});
export const loanYupSchema = yup.object({
    amount: monetaryAmountYupSchema,
    extraMonthlyRepayments: yup.array().of(extraMonthlyRepaymentYupSchema)
});
