import { UK_PLAN1_REPAYMENT_THRESHOLDS } from "./UK_PLAN1";
import { UK_PLAN2_REPAYMENT_THRESHOLDS } from "./UK_PLAN2";
import { UK_PLAN3_REPAYMENT_THRESHOLDS } from "./UK_PLAN3";
import { UK_PLAN4_REPAYMENT_THRESHOLDS } from "./UK_PLAN4";
import { UK_PLAN5_REPAYMENT_THRESHOLDS } from "./UK_PLAN5";
export const REPAYMENT_THRESHOLDS = {
    UK_PLAN1: UK_PLAN1_REPAYMENT_THRESHOLDS,
    UK_PLAN2: UK_PLAN2_REPAYMENT_THRESHOLDS,
    UK_PLAN3: UK_PLAN3_REPAYMENT_THRESHOLDS,
    UK_PLAN4: UK_PLAN4_REPAYMENT_THRESHOLDS,
    UK_PLAN5: UK_PLAN5_REPAYMENT_THRESHOLDS
};
