import { GraphQLScalarType, Kind } from "graphql";
import { isValid, parseISO, startOfMonth } from "date-fns";
// Outgoing Date as string (i.e, a Date within the code and a string in the JSON)
const serialize = (value)=>{
    return value;
};
// Incoming string (yyyy-MM) to Date to use internally
const parseValue = (value)=>{
    startOfMonth(parseISO(value));
    return value;
};
const parseLiteral = (ast)=>{
    if (ast.kind === Kind.STRING) {
        if (isValid(startOfMonth(parseISO(ast.value)))) {
            return ast.value;
        }
    }
    return null;
};
export const dateAsYearAndMonthResolver = new GraphQLScalarType({
    name: "DateAsYearAndMonth",
    serialize,
    parseValue,
    parseLiteral
});
