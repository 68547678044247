export const percentileIncomes = [
    //1
    16698,
    //9
    17908,
    // 19
    21054,
    // 29
    24079,
    // 39
    27346,
    // 49
    31460,
    // 59
    36300,
    // 69
    42955,
    // 79
    52393,
    // 89
    68607,
    // 99
    183000
];
