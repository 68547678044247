import { PlanType } from "@slrc/calculator-service/graphql-schema";
export const countries = [
    "england",
    "wales",
    "scotland",
    "northern-ireland"
];
export const countryToReadableMap = {
    england: {
        country: "England",
        person: "English"
    },
    wales: {
        country: "Wales",
        person: "Welsh"
    },
    scotland: {
        country: "Scotland",
        person: "Scottish"
    },
    "northern-ireland": {
        country: "Northern Ireland",
        person: "Northern Irish"
    }
};
export const countryConfigMap = {
    england: {
        sf: {
            text: "Student Finance England",
            url: "https://studentfinance.campaign.gov.uk/"
        },
        plans: [
            PlanType.UK_PLAN1,
            PlanType.UK_PLAN2,
            PlanType.UK_PLAN5,
            PlanType.UK_PLAN3
        ]
    },
    wales: {
        sf: {
            text: "Student Finance Wales",
            url: "https://www.studentfinancewales.co.uk/"
        },
        plans: [
            PlanType.UK_PLAN1,
            PlanType.UK_PLAN2,
            PlanType.UK_PLAN5,
            PlanType.UK_PLAN3
        ]
    },
    scotland: {
        sf: {
            text: "Student Awards Agency Scotland",
            url: "https://www.saas.gov.uk/"
        },
        plans: [
            PlanType.UK_PLAN4
        ]
    },
    "northern-ireland": {
        sf: {
            text: "Student Finance Northern Ireland",
            url: "https://www.studentfinanceni.co.uk/"
        },
        plans: [
            PlanType.UK_PLAN1
        ]
    }
};
