export const links = [
    {
        text: "Calculator",
        url: "/"
    },
    {
        text: "Loans Explained",
        url: "/articles/student-loans-explained"
    },
    {
        text: "Articles",
        url: "/articles"
    }
];
