// from https://github.com/vinissimus/next-translate/issues/721#issuecomment-966266255
import useTranslation from "next-translate/useTranslation";
export const useTypeSafeTranslation = (ns)=>{
    const { t , lang  } = useTranslation(ns);
    return {
        t: (s, q, o)=>t(s, q, o),
        tList: (s, q, o)=>t(s, q, {
                ...o,
                returnObjects: true
            }),
        lang
    };
};
