import { getInterestRatePercentage } from "./get-interest-rate-percentage";
import { getDaysInMonth, getDaysInYear } from "date-fns";
import { defaultGetInterestRateForPlanAndDate, defaultGetRepaymentThresholdForPlanAndDate } from "../repayment/calculate-mandatory-payment-to-make";
export const calculateInterestToAdd = (plan, date, currentIncome, currentDebt, repaymentThresholdMultiplierForYear, futureBaseInterestRate, futureOverrides)=>{
    // Get the interest rate for the given plan and date
    const interestRate = defaultGetInterestRateForPlanAndDate(plan, date, futureBaseInterestRate, futureOverrides);
    // Get the repayment threshold for the given plan and date
    const repaymentThreshold = defaultGetRepaymentThresholdForPlanAndDate(plan, date, repaymentThresholdMultiplierForYear, futureOverrides);
    // Use income, the interest rate and repayment threshold info to calculate the interest rate percentage
    const interestRatePercentage = getInterestRatePercentage(currentIncome, interestRate, repaymentThreshold);
    const interestRatePercentageDaily = interestRatePercentage / 100 / getDaysInYear(date);
    const interestToAdd = (Math.pow(interestRatePercentageDaily + 1, getDaysInMonth(date)) - 1) * currentDebt;
    return {
        interestToAdd,
        interestRatePercentage
    };
};
