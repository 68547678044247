module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': ['common'],
    '/': ['calculator', 'calculation'],
    '/about': ['about'],
    '/faq': ['faq'],
    '/data': ['data'],
    '/insights': ['insights'],
    '/articles': ['articles'],
    '/developer-api': ['developer-api'],
    '/privacy': ['privacy'],
  },
  interpolation: {
    prefix: '${',
    suffix: '}',
  },
  // TODO: dynamic import as require loads all at once which is not great
  loadLocaleFrom: async (lang, ns) =>
    require(`libs/slrc-web/localisation/src/locales/${lang}/${ns}.json`),
};
