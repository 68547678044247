import { CONTENTFUL_HOST, CONTENTFUL_SPACE_ID, CONTENTFUL_ACCESS_KEY, SLRC_WEB_IS_PRODUCTION } from "@slrc/env";
import { createClient } from "contentful";
import { isAfter } from "date-fns";
import { parseStringDate } from "@slrc/shared/utils";
import { mapContentfulArticleDetails } from "./load-article";
export async function loadArticles() {
    const client = createClient({
        host: CONTENTFUL_HOST,
        space: CONTENTFUL_SPACE_ID,
        accessToken: CONTENTFUL_ACCESS_KEY
    });
    const entries = await client.getEntries({
        content_type: "article",
        select: "fields.nonProdOnly,fields.title,fields.slug,fields.description,fields.publishDate,fields.updateDate,fields.heroImage"
    });
    const articles = entries.items.filter((entry)=>entry.fields.slug !== undefined && entry.fields.nonProdOnly !== undefined).filter((entry)=>{
        if (SLRC_WEB_IS_PRODUCTION) {
            return !entry.fields.nonProdOnly;
        }
        return true;
    }).sort((a, b)=>{
        const aDate = a.fields.updateDate ? a.fields.updateDate : a.fields.publishDate;
        const bDate = b.fields.updateDate ? b.fields.updateDate : b.fields.publishDate;
        if (isAfter(parseStringDate(aDate), parseStringDate(bDate))) {
            return -1;
        }
        return 1;
    }).map((entry)=>mapContentfulArticleDetails(entry));
    return articles;
}
