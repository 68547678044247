import { mq } from '@slrc/shared/ui';
import { css } from 'styled-components';

export const AppStyle = css`
  :root {
    /* https://hihayk.github.io/scale/#4/5/10/90/-10/10/0/0/3d7af5/61/122/245/white */
    --slrc-colour-primary-10: hsla(210, 50%, 98%, 1);
    --slrc-colour-primary-50: hsla(210, 90%, 96%, 1);
    --slrc-colour-primary-100: hsla(212, 89%, 89%, 1);
    --slrc-colour-primary-200: hsla(214, 89%, 82%, 1);
    --slrc-colour-primary-300: hsla(216, 91%, 75%, 1);
    --slrc-colour-primary-400: hsla(218, 90%, 67%, 1);
    --slrc-colour-primary-500: hsla(220, 90%, 60%, 1); // #3d7af5
    --slrc-colour-primary-500-invisible: hsla(220, 90%, 60%, 0);
    --slrc-colour-primary-600: hsla(223, 85%, 59%, 1);
    --slrc-colour-primary-700: hsla(225, 80%, 57%, 1);
    --slrc-colour-primary-800: hsla(227, 75%, 55%, 1);
    --slrc-colour-primary-900: hsla(230, 71%, 54%, 1);

    --slrc-colour-income-lowest-300: #e1eaea;
    --slrc-colour-income-lowest-500: #b8c6c9;

    --slrc-colour-income-low-300: #bed4db;
    --slrc-colour-income-low-500: #273c41;

    --slrc-colour-income-middle-300: #dbf1ed;
    --slrc-colour-income-middle-500: #62c8b2;

    --slrc-colour-income-high-300: #ede2de;
    --slrc-colour-income-high-500: #d5bab1;

    --slrc-colour-income-highest-300: #ded3dd;
    --slrc-colour-income-highest-500: #8d6c8a;

    /* ================================ */

    --slrc-colour-calculation-income-500: #a841e5;
    --slrc-colour-calculation-debt-500: #ebb734;
    --slrc-colour-calculation-interest-500: #eb4f34;
    --slrc-colour-calculation-mandatory-500: #3da5f5;

    --slrc-colour-calculation-voluntary-50: #f5fff9;
    --slrc-colour-calculation-voluntary-300: #4fd685;
    --slrc-colour-calculation-voluntary-500: #2aa85d;

    --slrc-colour-calculation-combined-500: #22628c;

    /* ================================ */

    --slrc-colour-calculation-outcome-paid-off-500: #ffcc73;
    --slrc-colour-calculation-outcome-written-off-500: #a3e356;
  }

  html {
    scroll-padding-top: 60px;
  }

  #nprogress .bar {
    background-color: var(--slrc-colour-primary-500) !important;
  }

  #nprogress .spinner-icon {
    border-top-color: var(--slrc-colour-primary-500) !important;
    border-left-color: var(--slrc-colour-primary-500) !important;
  }

  a {
    color: var(--slrc-colour-primary-600);
    text-decoration: none;
    font-weight: 900;
  }

  a:hover {
    color: var(--slrc-colour-primary-500);
  }

  .floatingchat-container-wrap,
  .floatingchat-container-wrap {
    ${mq({ display: ['none', 'block'] })}
  }
`;
