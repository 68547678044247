import { useEffect, useState } from "react";
export function useIntersectionObserver(elementRef, param) {
    let { threshold =0 , root =null , rootMargin ="0%" , freezeOnceVisible =false  } = param;
    const [entry, setEntry] = useState();
    const frozen = (entry === null || entry === void 0 ? void 0 : entry.isIntersecting) && freezeOnceVisible;
    const updateEntry = (param)=>{
        let [entry] = param;
        setEntry(entry);
    };
    const stringifiedThreshold = JSON.stringify(threshold);
    useEffect(()=>{
        const node = elementRef === null || elementRef === void 0 ? void 0 : elementRef.current; // DOM Ref
        const hasIOSupport = !!window.IntersectionObserver;
        if (!hasIOSupport || frozen || !node) return;
        const observerParams = {
            threshold,
            root,
            rootMargin
        };
        const observer = new IntersectionObserver(updateEntry, observerParams);
        observer.observe(node);
        return ()=>observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        elementRef === null || elementRef === void 0 ? void 0 : elementRef.current,
        stringifiedThreshold,
        root,
        rootMargin,
        frozen
    ]);
    return entry;
}
