import { PlanType } from "@slrc/calculator-service/graphql-schema";
import { parseStringDate } from "@slrc/shared/utils";
const PLAN_1_MAX_YEARS = 25;
const PLAN_2_MAX_YEARS = 30;
const PLAN_3_MAX_YEARS = 30;
const PLAN_4_MAX_YEARS = 30;
const PLAN_5_MAX_YEARS = 40;
export const getMaxYearsForPlan = (plan)=>{
    switch(plan){
        case PlanType.UK_PLAN1:
            return PLAN_1_MAX_YEARS;
        case PlanType.UK_PLAN2:
            return PLAN_2_MAX_YEARS;
        case PlanType.UK_PLAN3:
            return PLAN_3_MAX_YEARS;
        case PlanType.UK_PLAN4:
            return PLAN_4_MAX_YEARS;
        case PlanType.UK_PLAN5:
            return PLAN_5_MAX_YEARS;
    }
};
export const getWriteOffDateForLoan = (loan)=>{
    switch(loan.plan){
        case PlanType.UK_PLAN1:
            return getWriteOffDateForPlan1Loan(loan);
        case PlanType.UK_PLAN2:
            return getWriteOffDateForPlan2Loan(loan);
        case PlanType.UK_PLAN3:
            return getWriteOffDateForPlan3Loan(loan);
        case PlanType.UK_PLAN4:
            return getWriteOffDateForPlan4Loan(loan);
        case PlanType.UK_PLAN5:
            return getWriteOffDateForPlan5Loan(loan);
    }
};
const getWriteOffDateForPlan1Loan = (loan)=>{
    const { graduationYear  } = loan;
    // If graduation year is <= 2008 it's when you're 65
    if (graduationYear <= 2008) {
        // + 47 because we assume you are 21 when you graduate
        // TODO: ask for year of birth
        return parseStringDate("".concat(graduationYear + 47, "-04-30"));
    }
    // Otherwise it's 25 years after the April you were first due to repay
    // + 1 and + 25 because it is the April after graduation and then 25 years
    return parseStringDate("".concat(graduationYear + 1 + getMaxYearsForPlan(loan.plan), "-04-30"));
};
const getWriteOffDateForPlan2Loan = (loan)=>{
    const { graduationYear  } = loan;
    return parseStringDate("".concat(graduationYear + 1 + getMaxYearsForPlan(loan.plan), "-04-30"));
};
// Plan 3 is the postgraduate loan
const getWriteOffDateForPlan3Loan = (loan)=>{
    const { graduationYear  } = loan;
    return parseStringDate("".concat(graduationYear + 1 + getMaxYearsForPlan(loan.plan), "-04-30"));
};
const getWriteOffDateForPlan4Loan = (loan)=>{
    const { graduationYear  } = loan;
    return parseStringDate("".concat(graduationYear + 1 + getMaxYearsForPlan(loan.plan), "-04-30"));
};
const getWriteOffDateForPlan5Loan = (loan)=>{
    const { graduationYear  } = loan;
    return parseStringDate("".concat(graduationYear + 1 + getMaxYearsForPlan(loan.plan), "-04-30"));
};
