import { useContext } from "react";
import { SupabaseContext } from "./SupabaseProvider";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
export const useSupabase = ()=>{
    const context = useContext(SupabaseContext);
    if (context === undefined) {
        throw new Error("useSupabase must be used inside SupabaseProvider");
    }
    return context;
};
const useSession = ()=>{
    const { supabase  } = useSupabase();
    const result = useQuery([
        "getSession"
    ], async ()=>{
        try {
            const { data: { session  }  } = await supabase.auth.getSession();
            return session;
        } catch (error) {
            console.error("Error:", error);
            return null;
        }
    });
    return result;
};
export const useAuthenticated = ()=>{
    const { data: session  } = useSession();
    return {
        user: session === null || session === void 0 ? void 0 : session.user
    };
};
export const useSignOut = ()=>{
    const router = useRouter();
    const { supabase  } = useSupabase();
    const signOut = async ()=>{
        await supabase.auth.signOut();
        router.push("/");
    };
    return {
        signOut
    };
};
// TODO: needed?
// export const useUserDetails = () => {
//   const { supabase } = useSupabase();
//   const result = useQuery(['getUserDetails'], async () => {
//     try {
//       const { data: userDetails } = await supabase
//         .from('users')
//         .select('*')
//         .single();
//       return userDetails;
//     } catch (error) {
//       console.error('Error:', error);
//       return null;
//     }
//   });
//   return result;
// };
export const useSubscription = ()=>{
    const { supabase  } = useSupabase();
    const result = useQuery([
        "getSubscription"
    ], async ()=>{
        try {
            const { data: subscription  } = await supabase.from("subscriptions").select("*, prices(*, products(*))").in("status", [
                "trialing",
                "active"
            ]).maybeSingle().throwOnError();
            return subscription;
        } catch (error) {
            console.error("Error:", error);
            return null;
        }
    });
    return result;
};
export const useActiveProductsWithPrices = ()=>{
    const { supabase  } = useSupabase();
    const result = useQuery([
        "getActiveProductsWithPrices"
    ], async ()=>{
        const { data , error  } = await supabase.from("products").select("*, prices(*)").eq("active", true).eq("prices.active", true).order("metadata->index").order("unit_amount", {
            foreignTable: "prices"
        });
        if (error) {
            console.log(error.message);
        }
        return data !== null && data !== void 0 ? data : [];
    });
    return result;
};
