import { getYear } from 'date-fns';
import { DefaultSeoProps } from 'next-seo';

export const SEO_BASE_URL = 'https://www.yourstudentloancalculator.co.uk';

export function SEO(): DefaultSeoProps {
  const currentYear = getYear(new Date());

  const seoTitle = 'Your Student Loan Calculator';
  const seoDescription =
    'Helping you better understand the UK student finance system and loan repayments.';

  const seoProps: DefaultSeoProps = {
    title: seoTitle,
    description: seoDescription,
    openGraph: {
      title: seoTitle,
      description: seoDescription,
      type: 'website',
      locale: 'en_GB',
      url: SEO_BASE_URL,
      site_name: `UK Student Loan Repayment Calculator ${currentYear}`,
      images: [
        {
          url: 'https://slrc-assets-public.s3.eu-west-1.amazonaws.com/open-graph-banner.png',
          alt: 'SLRC banner',
          width: 1200,
          height: 630,
        },
      ],
    },
    twitter: {
      handle: '@YourSLRC',
      site: '@YourSLRC',
      cardType: 'summary_large_image',
    },
    additionalLinkTags: [
      { rel: 'shortcut icon', href: '/favicon.ico' },
      {
        rel: 'icon',
        sizes: '16x16 32x32 64x64',
        href: '/favicon.ico',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/favicon-32.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/favicon-16.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: '/apple-touch-icon.png',
      },
      {
        rel: 'mask-icon',
        color: '#3d7af5',
        href: '/safari-pinned-tab.svg',
      },
    ],
    additionalMetaTags: [
      {
        name: 'msapplication-TileColor',
        content: '#3d7af5',
      },
      {
        name: 'theme-color',
        content: '#3d7af5',
      },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1',
      },
    ],
  };

  return seoProps;
}
