import { graduationYearYupSchema, monetaryAmountSmallYupSchema, monetaryAmountYupSchema, percentageAmountYupSchema, planInput, futureIncomeYearYupSchema, dateYupSchema } from "@slrc/calculator-service/rules";
import { DEFAULT_FUTURE_BASE_INTEREST_RATE, DEFAULT_FUTURE_INCOME_GROWTH, DEFAULT_FUTURE_INFLATION_RATE, DEFAULT_FUTURE_REPAYMENT_THRESHOLD_GROWTH, MAX_FUTURE_INCOMES_COUNT, MAX_FUTURE_OVERRIDES_COUNT, MAX_VOLUNTARY_PAYMENTS_COUNT } from "@slrc/calculator-service/constants";
import * as yup from "yup";
import { PlanType } from "@slrc/calculator-service/graphql-schema";
import { getYear, subYears } from "date-fns";
const futureOverrideSchema = yup.object({
    year: futureIncomeYearYupSchema,
    repaymentThreshold: monetaryAmountYupSchema,
    inflationRate: percentageAmountYupSchema,
    baseInterestRate: percentageAmountYupSchema
});
const futureIncomeSchema = yup.object({
    year: futureIncomeYearYupSchema,
    income: monetaryAmountYupSchema
});
const voluntaryPaymentSchema = yup.object({
    // form management
    voluntaryPaymentType: yup.string().nullable(),
    //
    on: yup.date().when("voluntaryPaymentType", {
        is: (v)=>v === "lumpSum",
        then: dateYupSchema,
        otherwise: yup.date().nullable().notRequired()
    }),
    from: yup.date().when("voluntaryPaymentType", {
        is: (v)=>v === "monthly",
        then: dateYupSchema,
        otherwise: yup.date().nullable().notRequired()
    }),
    to: yup.date().when("voluntaryPaymentType", {
        is: (v)=>v === "monthly",
        then: dateYupSchema,
        otherwise: yup.date().nullable().notRequired()
    }),
    amountLump: yup.number().when("voluntaryPaymentType", {
        is: (v)=>v === "lumpSum",
        then: monetaryAmountSmallYupSchema,
        otherwise: yup.number().transform((value)=>isNaN(value) ? undefined : value).nullable().notRequired()
    }),
    amountMonthly: yup.number().when("voluntaryPaymentType", {
        is: (v)=>v === "monthly",
        then: monetaryAmountSmallYupSchema,
        otherwise: yup.number().transform((value)=>isNaN(value) ? undefined : value).nullable().notRequired()
    })
});
const otherLoanSchema = yup.object({
    // form management
    checked: yup.boolean(),
    //
    plan: planInput,
    debt: yup.number().when("checked", {
        is: true,
        then: monetaryAmountYupSchema,
        otherwise: yup.number().transform((value)=>isNaN(value) ? undefined : value).nullable().notRequired()
    }),
    graduationYear: yup.number().when("checked", {
        is: true,
        then: graduationYearYupSchema,
        otherwise: yup.number().transform((value)=>isNaN(value) ? undefined : value).nullable().notRequired()
    }),
    voluntaryPayments: yup.array().when("checked", {
        is: true,
        then: yup.array().max(MAX_VOLUNTARY_PAYMENTS_COUNT).of(voluntaryPaymentSchema),
        otherwise: yup.array().nullable().notRequired()
    }),
    futureOverrides: yup.array().max(MAX_FUTURE_OVERRIDES_COUNT).of(futureOverrideSchema)
});
export const formSchema = yup.object({
    // form management
    useRealValues: yup.boolean(),
    //
    // MAIN LOAN
    income: monetaryAmountYupSchema,
    plan: planInput,
    debt: monetaryAmountYupSchema,
    graduationYear: graduationYearYupSchema,
    voluntaryPayments: yup.array().max(MAX_VOLUNTARY_PAYMENTS_COUNT).of(voluntaryPaymentSchema),
    // ADVANCED OPTIONS
    incomeGrowth: percentageAmountYupSchema,
    repaymentThresholdGrowth: percentageAmountYupSchema,
    inflationRate: percentageAmountYupSchema,
    baseInterestRate: percentageAmountYupSchema,
    futureIncomes: yup.array().max(MAX_FUTURE_INCOMES_COUNT).of(futureIncomeSchema),
    // OTHER LOANS
    otherLoans: yup.object({
        UK_PLAN1: otherLoanSchema,
        UK_PLAN2: otherLoanSchema,
        UK_PLAN3: otherLoanSchema,
        UK_PLAN4: otherLoanSchema,
        UK_PLAN5: otherLoanSchema
    })
}).required();
export const formSchemaDefaultValues = {
    plan: PlanType.UK_PLAN2,
    graduationYear: getYear(subYears(new Date(), 1)),
    incomeGrowth: DEFAULT_FUTURE_INCOME_GROWTH,
    repaymentThresholdGrowth: DEFAULT_FUTURE_REPAYMENT_THRESHOLD_GROWTH,
    inflationRate: DEFAULT_FUTURE_INFLATION_RATE,
    baseInterestRate: DEFAULT_FUTURE_BASE_INTEREST_RATE,
    otherLoans: {
        UK_PLAN1: {
            checked: false,
            plan: PlanType.UK_PLAN1
        },
        UK_PLAN2: {
            checked: false,
            plan: PlanType.UK_PLAN2
        },
        UK_PLAN3: {
            checked: false,
            plan: PlanType.UK_PLAN3
        },
        UK_PLAN4: {
            checked: false,
            plan: PlanType.UK_PLAN4
        },
        UK_PLAN5: {
            checked: false,
            plan: PlanType.UK_PLAN5
        }
    }
};
