import { clone } from "ramda";
import { getIncomeForYear } from "../income/get-income-for-year";
import { createMonetaryAmount } from "./create-monetary-amount";
export const updateLoanSupportWhenEndOfYear = (year, fromYear, loanSupport, futureIncomes, futureIncomeGrowth, futureRepaymentThresholdGrowth, inflationRates, inflationRate)=>{
    // Calculate inflationMultiplierForYear
    const yearsDiff = year - fromYear + 1;
    const inflationRatesForYearsRemaining = Array(yearsDiff).fill(0).map((_, i)=>{
        const y = fromYear + i + 1;
        const potentialInflationRate = inflationRates.find((i)=>i.year === y);
        return {
            year: y,
            inflationRate: potentialInflationRate ? potentialInflationRate.inflationRate : inflationRate
        };
    });
    const potentialInflationRate = inflationRates.find((i)=>i.year === year);
    const inflationRateForYear = potentialInflationRate ? potentialInflationRate.inflationRate : inflationRate;
    const inflationMultiplierForYear = inflationRatesForYearsRemaining.reduce((acc, cur)=>{
        const value = acc * (1 - cur.inflationRate / 100);
        return value;
    }, 1);
    loanSupport.inflationRateForYear = inflationRateForYear;
    loanSupport.inflationMultiplierForYear = inflationMultiplierForYear;
    // Calculate repaymentThresholdMultiplierForYear
    const repaymentThresholdMultiplierForYear = Math.pow(1 + futureRepaymentThresholdGrowth / 100, yearsDiff);
    loanSupport.repaymentThresholdMultiplierForYear = repaymentThresholdMultiplierForYear;
    // Calculate new income to be used in following year
    const newIncome = getIncomeForYear(year, loanSupport.currentIncome, futureIncomes, futureIncomeGrowth);
    loanSupport.currentIncome = newIncome;
    // Reset/update yearSummary and months
    loanSupport.yearSummary = {
        year: year + 1,
        averageInterestRate: 0,
        averageRepaymentThreshold: createMonetaryAmount(0, 0),
        interestAddedInYear: createMonetaryAmount(0, 0),
        interestAddedCumulative: clone(loanSupport.yearSummary.interestAddedCumulative),
        mandatoryPaymentsMadeInYear: createMonetaryAmount(0, 0),
        mandatoryPaymentsMadeCumulative: clone(loanSupport.yearSummary.mandatoryPaymentsMadeCumulative),
        voluntaryPaymentsMadeInYear: createMonetaryAmount(0, 0),
        voluntaryPaymentsMadeCumulative: clone(loanSupport.yearSummary.voluntaryPaymentsMadeCumulative),
        remainingDebtInYear: createMonetaryAmount(Math.max(0, loanSupport.currentDebt), Math.max(0, loanSupport.currentDebt * inflationMultiplierForYear)),
        income: createMonetaryAmount(newIncome, newIncome * inflationMultiplierForYear)
    };
    loanSupport.months = [];
};
