import { useRouter } from "next/router";
import { useEffect } from "react";
import * as Fathom from "fathom-client";
export function useFathomPageView(isProduction) {
    const router = useRouter();
    useEffect(()=>{
        if (isProduction) {
            Fathom.load("YOKXDLGM", {
                // TODO: this is returning a 403
                // url: 'https://web.yourstudentloancalculator.co.uk/script.js',
                includedDomains: [
                    "yourstudentloancalculator.co.uk",
                    "www.yourstudentloancalculator.co.uk"
                ]
            });
        }
        const routeChangeComplete = (url)=>{
            if (isProduction) {
                Fathom.trackPageview({
                    url
                });
            }
        };
        router.events.on("routeChangeComplete", routeChangeComplete);
        return ()=>{
            router.events.off("routeChangeComplete", routeChangeComplete);
        };
    }, [
        isProduction,
        router.events
    ]);
}
