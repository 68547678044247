import { clone, remove } from "ramda";
import { getRepaymentStartDateForLoan } from "../dates/get-repayment-start-date-for-loan";
import { getWriteOffDateForLoan } from "../dates/get-write-off-date-for-loan";
import { createMonetaryAmount } from "./create-monetary-amount";
import { getYear } from "date-fns";
export const createLoanSupports = (income, loans, from)=>{
    const loanSupports = {};
    loans.forEach((loan, loanIndex)=>{
        loanSupports[loan.plan] = {
            repaymentStartDate: getRepaymentStartDateForLoan(loan),
            writeOffDate: getWriteOffDateForLoan(loan),
            months: [],
            yearSummary: {
                year: getYear(from),
                averageInterestRate: 0,
                averageRepaymentThreshold: createMonetaryAmount(0, 0),
                interestAddedInYear: createMonetaryAmount(0, 0),
                interestAddedCumulative: createMonetaryAmount(0, 0),
                mandatoryPaymentsMadeInYear: createMonetaryAmount(0, 0),
                mandatoryPaymentsMadeCumulative: createMonetaryAmount(0, 0),
                voluntaryPaymentsMadeInYear: createMonetaryAmount(0, 0),
                voluntaryPaymentsMadeCumulative: createMonetaryAmount(0, 0),
                remainingDebtInYear: createMonetaryAmount(loan.amount, loan.amount),
                income: createMonetaryAmount(income, income)
            },
            calculationDone: false,
            currentIncome: income,
            currentDebt: loan.amount,
            otherLoansNotYetPaidOrWrittenOff: remove(loanIndex, 1, clone(loans)).map((l)=>l.plan),
            inflationRateForYear: 0,
            inflationMultiplierForYear: 1,
            repaymentThresholdMultiplierForYear: 1
        };
    });
    return loanSupports;
};
