import { parseStringDate } from "@slrc/shared/utils";
import { getYear, isWithinInterval } from "date-fns";
import { REPAYMENT_THRESHOLDS } from "./repayment-thresholds";
export const getRepaymentThresholdForPlanAndDateFromRepository = (plan, date, repaymentThresholdMultiplierForYear, futureOverrides)=>{
    const planRepaymentThresholds = REPAYMENT_THRESHOLDS[plan];
    const repaymentThreshold = planRepaymentThresholds.find((param)=>{
        let { from , to  } = param;
        return isWithinInterval(date, {
            start: parseStringDate(from),
            end: parseStringDate(to)
        });
    });
    if (repaymentThreshold) {
        return repaymentThreshold;
    }
    // Create new future repayment threshold with repaymentThresholdMultiplierForYear
    // or pull one from futureOverrides
    const lastRepaymentThreshold = planRepaymentThresholds[planRepaymentThresholds.length - 1];
    const percentageDiffBetweenMinAndMax = lastRepaymentThreshold.max !== undefined ? lastRepaymentThreshold.max / lastRepaymentThreshold.min : 1;
    if (futureOverrides.length) {
        const year = getYear(date);
        const futureRepaymentThresholdInYear = futureOverrides.find((p)=>p.year === year);
        if (futureRepaymentThresholdInYear && futureRepaymentThresholdInYear.repaymentThreshold !== undefined) {
            return {
                ...lastRepaymentThreshold,
                min: futureRepaymentThresholdInYear.repaymentThreshold,
                ...lastRepaymentThreshold.max !== undefined ? {
                    max: futureRepaymentThresholdInYear.repaymentThreshold * percentageDiffBetweenMinAndMax
                } : {}
            };
        }
    }
    return {
        ...lastRepaymentThreshold,
        min: repaymentThresholdMultiplierForYear * lastRepaymentThreshold.min,
        ...lastRepaymentThreshold.max !== undefined ? {
            max: repaymentThresholdMultiplierForYear * lastRepaymentThreshold.max
        } : {}
    };
};
