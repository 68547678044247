// @ts-nocheck
import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    const data = _tagged_template_literal([
        '\n  schema {\n    query: Query\n    mutation: Mutation\n  }\n  type Insights {\n    plansDistribution: [PlanDistribution!]!\n  }\n  type Mutation {\n    "Articles"\n    incrementArticleHits(articleId: String!): Int\n  }\n  type PlanDistribution {\n    amount: Float!\n    plan: String!\n  }\n  type Query {\n    "Articles"\n    getArticleHits(articleId: String!): Int\n    "Insights"\n    getInsights: Insights!\n  }\n'
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
import { useMutation, useQuery } from "@tanstack/react-query";
function fetcher(client, query, variables, requestHeaders) {
    return async ()=>client.request({
            document: query,
            variables,
            requestHeaders
        });
}
export const IncrementArticleHitsDocument = "\n    mutation IncrementArticleHits($articleId: String!) {\n  incrementArticleHits(articleId: $articleId)\n}\n    ";
export const useIncrementArticleHitsMutation = (client, options, headers)=>useMutation([
        "IncrementArticleHits"
    ], (variables)=>fetcher(client, IncrementArticleHitsDocument, variables, headers)(), options);
useIncrementArticleHitsMutation.getKey = ()=>[
        "IncrementArticleHits"
    ];
useIncrementArticleHitsMutation.fetcher = (client, variables, headers)=>fetcher(client, IncrementArticleHitsDocument, variables, headers);
export const GetArticleHitsDocument = "\n    query GetArticleHits($articleId: String!) {\n  getArticleHits(articleId: $articleId)\n}\n    ";
export const useGetArticleHitsQuery = (client, variables, options, headers)=>useQuery([
        "GetArticleHits",
        variables
    ], fetcher(client, GetArticleHitsDocument, variables, headers), options);
useGetArticleHitsQuery.getKey = (variables)=>[
        "GetArticleHits",
        variables
    ];
useGetArticleHitsQuery.fetcher = (client, variables, headers)=>fetcher(client, GetArticleHitsDocument, variables, headers);
export const GetInsightsDocument = "\n    query GetInsights {\n  getInsights {\n    plansDistribution {\n      plan\n      amount\n    }\n  }\n}\n    ";
export const useGetInsightsQuery = (client, variables, options, headers)=>useQuery(variables === undefined ? [
        "GetInsights"
    ] : [
        "GetInsights",
        variables
    ], fetcher(client, GetInsightsDocument, variables, headers), options);
useGetInsightsQuery.getKey = (variables)=>variables === undefined ? [
        "GetInsights"
    ] : [
        "GetInsights",
        variables
    ];
useGetInsightsQuery.fetcher = (client, variables, headers)=>fetcher(client, GetInsightsDocument, variables, headers);
import { gql } from "graphql-request";
export const typeDefs = gql(_templateObject());
