import { createSlice } from "@reduxjs/toolkit";
const initialState = {};
export const mainLayoutSideSlice = createSlice({
    name: "mainLayoutSide",
    initialState,
    reducers: {
        setArticleToc: (state, action)=>{
            state.articleToc = action.payload;
        }
    }
});
export const { setArticleToc  } = mainLayoutSideSlice.actions;
export default mainLayoutSideSlice.reducer;
