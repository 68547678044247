import * as Sentry from "@sentry/nextjs";
export const reactQueryClientConfig = {
    defaultOptions: {
        queries: {
            // For true SSR
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 0,
            onError: (err)=>{
                Sentry.captureException(err);
            }
        },
        mutations: {
            retry: 0,
            onError: (err)=>{
                Sentry.captureException(err);
            }
        }
    }
};
