import { isDifferenceMore } from "../../../helpers";
import { format } from "date-fns";
import { outcomeTypeToReadableOutcomeTypeMap } from "@slrc/slrc-web/helpers";
import { MONTH_YEAR_TEXT_FORMAT } from "@slrc/shared/utils";
export const getSummaryFromInputAndCalculation = (loanInput, loanCalculation, useRealValues)=>{
    const { amount  } = loanInput;
    const { summary , years  } = loanCalculation;
    const { outcome , to , lifetimeMandatoryPaymentsMade , lifetimeVoluntaryPaymentsMade , remainingDebt , durationInYears , maxDurationInYears  } = summary;
    const { isMore , difference  } = isDifferenceMore(amount, lifetimeMandatoryPaymentsMade, lifetimeVoluntaryPaymentsMade, useRealValues);
    const readableOutcome = outcomeTypeToReadableOutcomeTypeMap[outcome];
    const readableTo = format(new Date(to), MONTH_YEAR_TEXT_FORMAT);
    const totalPaid = loanCalculation.summary.lifetimeMandatoryPaymentsMade[useRealValues ? "real" : "nominal"] + loanCalculation.summary.lifetimeVoluntaryPaymentsMade[useRealValues ? "real" : "nominal"];
    const paidUpcomingYear = years[0].summary.mandatoryPaymentsMadeInYear[useRealValues ? "real" : "nominal"] + years[0].summary.voluntaryPaymentsMadeInYear[useRealValues ? "real" : "nominal"];
    return {
        readableTo,
        readableOutcome,
        durationInYears,
        maxDurationInYears,
        amount,
        isMore,
        totalPaid,
        difference,
        paidUpcomingYear,
        remainingDebt: Math.max(0, remainingDebt[useRealValues ? "real" : "nominal"])
    };
};
