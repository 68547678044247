import { PlanType } from "@slrc/calculator-service/graphql-schema";
import { isBefore } from "date-fns";
import { findIndex, without } from "ramda";
import { getInterestRateForPlanAndDateFromRepository, getRepaymentThresholdForPlanAndDateFromRepository } from "@slrc/calculator-service/adapter";
// TODO: pulled from application lib because of circular dep ...
export const defaultGetRepaymentThresholdForPlanAndDate = (plan, date, repaymentThresholdMultiplierForYear, futureOverrides)=>{
    return getRepaymentThresholdForPlanAndDateFromRepository(plan, date, repaymentThresholdMultiplierForYear, futureOverrides);
};
export const defaultGetInterestRateForPlanAndDate = (plan, date, futureBaseInterestRate, futureOverrides)=>{
    return getInterestRateForPlanAndDateFromRepository(plan, date, futureBaseInterestRate, futureOverrides);
};
// To make it mockable in unit tests
export const getRepaymentThresholdForPlanAndDate = (plan, date, repaymentThresholdMultiplierForYear, futureOverrides)=>defaultGetRepaymentThresholdForPlanAndDate(plan, date, repaymentThresholdMultiplierForYear, futureOverrides);
export const calculateMandatoryPaymentToMake = (loanPlan, date, repaymentStartDate, currentIncome, otherLoansNotYetPaidOrWrittenOff, repaymentThresholdMultiplierForYear, futureOverrides)=>{
    // First make sure current date is not before repaymentStartDate
    if (isBefore(date, repaymentStartDate)) {
        return 0;
    }
    // Has only one loan
    //   or
    // Has more than one loan but is calculating for UK_PLAN3
    //   or
    // Has only two loans and one of those is UK_PLAN3
    if (otherLoansNotYetPaidOrWrittenOff.length === 0 || loanPlan === PlanType.UK_PLAN3 || otherLoansNotYetPaidOrWrittenOff.length === 1 && otherLoansNotYetPaidOrWrittenOff[0] === PlanType.UK_PLAN3) {
        const repaymentThreshold = getRepaymentThresholdForPlanAndDate(loanPlan, date, repaymentThresholdMultiplierForYear, futureOverrides);
        const incomeIsOverMinThreshold = currentIncome > repaymentThreshold.min;
        if (!incomeIsOverMinThreshold) {
            return 0;
        }
        const incomeAmountOverMinThreshold = currentIncome - repaymentThreshold.min;
        return incomeAmountOverMinThreshold * (repaymentThreshold.repaymentRate / 100) / 12;
    }
    // Has multiple loans and is calculating for one of them
    // You pay thresholds between and the remainder above the highest threshold to that loan
    const otherLoanPlansOrdered = getOtherLoanPlansOrdered(loanPlan, date, currentIncome, otherLoansNotYetPaidOrWrittenOff, repaymentThresholdMultiplierForYear, futureOverrides);
    const indexOfLoan = findIndex((p)=>loanPlan === p.type, otherLoanPlansOrdered);
    if (indexOfLoan < 0) {
        return 0;
    }
    const isLastIndex = indexOfLoan === otherLoanPlansOrdered.length - 1;
    const numberToTakeFrom = isLastIndex ? currentIncome : otherLoanPlansOrdered[indexOfLoan + 1].minRepaymentThreshold;
    const diff = numberToTakeFrom - otherLoanPlansOrdered[indexOfLoan].minRepaymentThreshold;
    return diff * (otherLoanPlansOrdered[indexOfLoan].rate / 100) / 12;
};
const getOtherLoanPlansOrdered = (loanPlan, date, currentIncome, otherLoansNotYetPaidOrWrittenOff, repaymentThresholdMultiplierForYear, futureOverrides)=>{
    // Combine this plan with the other loan plans, excluding any UK_PLAN3 loan
    const loanPlansWithoutPlan3 = without([
        PlanType.UK_PLAN3
    ], [
        loanPlan,
        ...otherLoansNotYetPaidOrWrittenOff
    ]);
    // Re-order the plans according to min threshold, to determine what to pay for this loanPlan
    return loanPlansWithoutPlan3.map((plan)=>{
        const rt = getRepaymentThresholdForPlanAndDate(plan, date, repaymentThresholdMultiplierForYear, futureOverrides);
        return {
            type: plan,
            minRepaymentThreshold: rt.min,
            rate: rt.repaymentRate
        };
    }).sort((a, b)=>{
        return a.minRepaymentThreshold - b.minRepaymentThreshold;
    }).filter((p)=>p.minRepaymentThreshold <= currentIncome);
};
