// REMEMBER TO IMPORT INTO GLOBAL NEXT APP
// import 'nprogress/nprogress.css';
import { useRouter } from "next/router";
import nprogress from "nprogress";
import { useEffect } from "react";
nprogress.configure({
    showSpinner: false
});
export function useNprogress() {
    const router = useRouter();
    useEffect(()=>{
        const routeChangeStart = ()=>{
            nprogress.start();
        };
        const routeChangeComplete = ()=>{
            nprogress.done();
        };
        const routeChangeError = ()=>{
            nprogress.done();
        };
        router.events.on("routeChangeStart", routeChangeStart);
        router.events.on("routeChangeComplete", routeChangeComplete);
        router.events.on("routeChangeError", routeChangeError);
        return ()=>{
            router.events.off("routeChangeStart", routeChangeStart);
            router.events.off("routeChangeComplete", routeChangeComplete);
            router.events.off("routeChangeError", routeChangeError);
        };
    }, [
        router.events
    ]);
}
