import { format, parseISO, startOfDay, addMinutes, subMinutes } from "date-fns";
export const YEAR_MONTH_DAY_FORMAT = "yyyy-MM-dd";
export const YEAR_MONTH_FORMAT = "yyyy-MM";
export const DAY_MONTH_YEAR_TEXT_FORMAT = "d MMMM yyyy";
export const MONTH_YEAR_TEXT_FORMAT = "MMMM yyyy";
export const MONTH_YEAR__SHORT_TEXT_FORMAT = "MMM yyyy";
export const createDate = (value)=>removeTzOffset(startOfDay(value));
export const parseStringDate = (value)=>removeTzOffset(startOfDay(parseISO(value)));
export const stringDateToDayTextMonthYearFormat = (value)=>format(parseStringDate(value), DAY_MONTH_YEAR_TEXT_FORMAT);
export const removeTzOffset = (date)=>{
    const dateOffset = date.getTimezoneOffset();
    return dateOffset >= 0 ? addMinutes(date, dateOffset) : subMinutes(date, dateOffset);
};
