import { OutcomeType } from "@slrc/calculator-service/graphql-schema";
import { YEAR_MONTH_FORMAT } from "@slrc/shared/utils";
import { differenceInCalendarMonths, format } from "date-fns";
export const updateLoanCalculationWhenLoanPaidOff = (from, date, loanCalculation)=>{
    const fromWhenPaidOff = format(from, YEAR_MONTH_FORMAT);
    const toWhenPaidOff = format(date, YEAR_MONTH_FORMAT);
    const durationWhenPaidOff = differenceInCalendarMonths(date, from) / 12.0;
    loanCalculation.summary.outcome = OutcomeType.PAID_OFF;
    loanCalculation.summary.from = fromWhenPaidOff;
    loanCalculation.summary.to = toWhenPaidOff;
    loanCalculation.summary.durationInYears = durationWhenPaidOff;
};
