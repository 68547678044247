import { useIncrementArticleHitsMutation } from "@slrc/slrc-service/graphql-schema";
import { slrcsServiceGqlClient } from "@slrc/slrc-web/data-fetching";
import { useEffect, useState } from "react";
export function useIncrementArticleHitsHook(articleId) {
    const [hits, setHits] = useState(0);
    const { mutate: incrementArticleHits  } = useIncrementArticleHitsMutation(slrcsServiceGqlClient);
    useEffect(()=>{
        incrementArticleHits({
            articleId
        }, {
            onSuccess: (param)=>{
                let { incrementArticleHits  } = param;
                if (incrementArticleHits) {
                    setHits(incrementArticleHits);
                }
            }
        });
    }, [
        articleId,
        incrementArticleHits
    ]);
    return {
        hits
    };
}
