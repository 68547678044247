import { funcForEachRecurse } from "@slrc/shared/utils";
import { clone } from "ramda";
export const modifyAllFloatsInCalculation = (calculation)=>{
    const clonedCalculation = clone(calculation);
    funcForEachRecurse(clonedCalculation, (key, value, o)=>{
        if (typeof value === "number" && !Number.isSafeInteger(value)) {
            o[key] = Math.round((value + Number.EPSILON) * 10) / 10;
        }
    });
    return clonedCalculation;
};
