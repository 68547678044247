import { YEAR_MONTH_FORMAT } from "@slrc/shared/utils";
import { format, startOfMonth } from "date-fns";
export const formValuesToInput = (formValues)=>{
    var _formValues_voluntaryPayments;
    const mainLoan = {
        plan: formValues.plan,
        amount: formValues.debt,
        graduationYear: formValues.graduationYear,
        extraMonthlyRepayments: (_formValues_voluntaryPayments = formValues.voluntaryPayments) === null || _formValues_voluntaryPayments === void 0 ? void 0 : _formValues_voluntaryPayments.filter((v)=>v.voluntaryPaymentType === "lumpSum" || v.voluntaryPaymentType === "monthly").flatMap((v)=>{
            if (v.voluntaryPaymentType === "monthly" && v.from && v.to) {
                return {
                    from: format(startOfMonth(v.from), YEAR_MONTH_FORMAT),
                    to: format(startOfMonth(v.to), YEAR_MONTH_FORMAT),
                    amount: v.amountMonthly
                };
            } else if (v.voluntaryPaymentType === "lumpSum" && v.on) {
                return {
                    from: format(startOfMonth(v.on), YEAR_MONTH_FORMAT),
                    to: format(startOfMonth(v.on), YEAR_MONTH_FORMAT),
                    amount: v.amountLump
                };
            }
            return [];
        })
    };
    const otherLoans = Object.values(formValues.otherLoans || {}).filter((o)=>o.checked).map((otherLoan)=>{
        var _otherLoan_voluntaryPayments;
        return {
            plan: otherLoan.plan,
            amount: otherLoan.debt,
            graduationYear: otherLoan.graduationYear,
            extraMonthlyRepayments: (_otherLoan_voluntaryPayments = otherLoan.voluntaryPayments) === null || _otherLoan_voluntaryPayments === void 0 ? void 0 : _otherLoan_voluntaryPayments.filter((v)=>v.voluntaryPaymentType === "lumpSum" || v.voluntaryPaymentType === "monthly").flatMap((v)=>{
                if (v.voluntaryPaymentType === "monthly" && v.from && v.to) {
                    return {
                        from: format(startOfMonth(v.from), YEAR_MONTH_FORMAT),
                        to: format(startOfMonth(v.to), YEAR_MONTH_FORMAT),
                        amount: v.amountMonthly
                    };
                } else if (v.voluntaryPaymentType === "lumpSum" && v.on) {
                    return {
                        from: format(startOfMonth(v.on), YEAR_MONTH_FORMAT),
                        to: format(startOfMonth(v.on), YEAR_MONTH_FORMAT),
                        amount: v.amountLump
                    };
                }
                return [];
            })
        };
    });
    const input = {
        income: formValues.income,
        loans: [
            mainLoan,
            ...otherLoans
        ],
        assumptions: {
            ...formValues.futureIncomes && {
                futureIncomes: formValues.futureIncomes
            },
            futureIncomeGrowth: formValues.incomeGrowth,
            futureRepaymentThresholdGrowth: formValues.repaymentThresholdGrowth,
            futureInflationRate: formValues.inflationRate,
            futureBaseInterestRate: formValues.baseInterestRate
        }
    };
    const inputWithoutExtraMonthlyRepayments = {
        ...input,
        loans: input.loans.map((param)=>{
            let { extraMonthlyRepayments =[] , ...restOfLoan } = param;
            return {
                ...restOfLoan
            };
        })
    };
    return {
        input,
        inputWithoutExtraMonthlyRepayments
    };
};
