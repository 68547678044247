import { YEAR_MONTH_FORMAT } from "@slrc/shared/utils";
import { isMatch } from "date-fns";
import * as yup from "yup";
export const dateAsYearAndMonthYupSchema = yup.string().test("is-date-year-and-month-format", "Please provide all from and to date formats as '".concat(YEAR_MONTH_FORMAT, "'"), (dateString)=>{
    if (dateString) {
        return isMatch(dateString, YEAR_MONTH_FORMAT);
    }
    return false;
}).required("Required");
export const dateYupSchema = yup.date().typeError("Required").required("Required");
