import { useEffect, useRef } from "react";
import { useIsomorphicLayoutEffect } from "./use-isomorphic-layout-effect";
export function useEventListener(eventName, handler, element, options) {
    // Create a ref that stores handler
    const savedHandler = useRef(handler);
    useIsomorphicLayoutEffect(()=>{
        savedHandler.current = handler;
    }, [
        handler
    ]);
    useEffect(()=>{
        var _element_current;
        // Define the listening target
        const targetElement = (_element_current = element === null || element === void 0 ? void 0 : element.current) !== null && _element_current !== void 0 ? _element_current : window;
        if (!(targetElement && targetElement.addEventListener)) return;
        // Create event listener that calls handler function stored in ref
        const listener = (event)=>savedHandler.current(event);
        targetElement.addEventListener(eventName, listener, options);
        // Remove event listener on cleanup
        return ()=>{
            targetElement.removeEventListener(eventName, listener, options);
        };
    }, [
        eventName,
        element,
        options
    ]);
}
