import { createClient } from "contentful";
import { serialize } from "next-mdx-remote/serialize";
import { CONTENTFUL_HOST, CONTENTFUL_ACCESS_KEY, CONTENTFUL_SPACE_ID } from "@slrc/env";
export async function loadArticleBySlug(context) {
    const { queryKey  } = context;
    const articleSlug = queryKey[1];
    const client = createClient({
        host: CONTENTFUL_HOST,
        space: CONTENTFUL_SPACE_ID,
        accessToken: CONTENTFUL_ACCESS_KEY
    });
    const entries = await client.getEntries({
        content_type: "article",
        "fields.slug[match]": articleSlug
    });
    const { fields  } = entries.items[0];
    const entry = entries.items[0];
    const { body , tableOfContents  } = fields;
    const article = {
        ...mapContentfulArticleDetails(entry),
        ...entry.fields.quote && {
            quote: entry.fields.quote
        },
        toc: tableOfContents || "",
        source: await serialize(body, {
            mdxOptions: {
                development: false
            }
        })
    };
    return article;
}
const genericHeroImageUrl = "https://slrc-assets-public.s3.eu-west-1.amazonaws.com/article-generic-hero.png";
export function mapContentfulArticleDetails(entry) {
    return {
        id: entry.sys.id,
        nonProdOnly: entry.fields.nonProdOnly,
        title: entry.fields.title || "__TITLE_NEEDED__",
        slug: entry.fields.slug,
        ...entry.fields.readTime && {
            readTime: entry.fields.readTime
        },
        description: entry.fields.description || "__DESCRIPTION_NEEDED__",
        publishDate: entry.fields.publishDate,
        ...entry.fields.updateDate && {
            updateDate: entry.fields.updateDate
        },
        // Generic article image if none provided/unable to load
        heroImageUrl: genericHeroImageUrl,
        ...entry.fields.heroImage && entry.fields.heroImage.fields && {
            heroImageUrl: "https:".concat(entry.fields.heroImage.fields.file.url)
        }
    };
}
