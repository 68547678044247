import * as yup from "yup";
import { MAX_PERCENTAGE_AMOUNT, MIN_GRADUATION_YEAR } from "@slrc/calculator-service/constants";
import { addYears, getYear } from "date-fns";
export const percentageAmountYupSchema = yup.number().min(-20, (param)=>{
    let { path , min  } = param;
    return "".concat(path, " cannot be less than ").concat(min);
}).max(MAX_PERCENTAGE_AMOUNT, (param)=>{
    let { path , max  } = param;
    return "".concat(path, " cannot be more than ").concat(max);
}).required("Required").typeError("Required");
export const graduationYearYupSchema = yup.number().min(MIN_GRADUATION_YEAR, "This calculator only works for people who graduated from ".concat(MIN_GRADUATION_YEAR, " onward"))// TODO: future graduation years should only be supported if you can choose a FROM in calculate-for-input so as to offset when debt is taken out
// currently not doing that so max it at sometime from now
// TODO also use constants for this
.max(getYear(addYears(new Date(), 7)), "Graduation year must be less than ".concat(getYear(addYears(new Date(), 7)))).required("We need to know your graduation year").typeError("We need to know your graduation year");
export const futureIncomeYearYupSchema = yup.number().min(getYear(new Date()), "Year has to be in the future").required("We need a year").typeError("We need a year");
