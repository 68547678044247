import { GraphQLScalarType, Kind } from "graphql";
import { parseISO, startOfDay, isValid } from "date-fns";
// Outgoing Date as string (i.e, a Date within the code and a string in the JSON)
const serialize = (value)=>{
    return value;
};
// Incoming string (yyyy-MM-dd) to Date to use internally
const parseValue = (value)=>{
    startOfDay(parseISO(value));
    return value;
};
const parseLiteral = (ast)=>{
    if (ast.kind === Kind.STRING) {
        if (isValid(startOfDay(parseISO(ast.value)))) {
            return ast.value;
        }
    }
    return null;
};
export const dateAsYearMonthAndDayResolver = new GraphQLScalarType({
    name: "DateAsYearMonthAndDay",
    serialize,
    parseValue,
    parseLiteral
});
