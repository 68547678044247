import { UK_PLAN1_INTEREST_RATES } from "./UK_PLAN1";
import { UK_PLAN2_INTEREST_RATES } from "./UK_PLAN2";
import { UK_PLAN3_INTEREST_RATES } from "./UK_PLAN3";
import { UK_PLAN4_INTEREST_RATES } from "./UK_PLAN4";
import { UK_PLAN5_INTEREST_RATES } from "./UK_PLAN5";
export const INTEREST_RATES = {
    UK_PLAN1: UK_PLAN1_INTEREST_RATES,
    UK_PLAN2: UK_PLAN2_INTEREST_RATES,
    UK_PLAN3: UK_PLAN3_INTEREST_RATES,
    UK_PLAN4: UK_PLAN4_INTEREST_RATES,
    UK_PLAN5: UK_PLAN5_INTEREST_RATES
};
