import { YEAR_MONTH_DAY_FORMAT } from "@slrc/shared/utils";
import { format } from "date-fns";
export const UK_PLAN5_REPAYMENT_THRESHOLDS = [
    {
        from: format(new Date("2026-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2027-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 25000,
        repaymentRate: 9
    }
];
