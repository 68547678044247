import { PlanType } from "@slrc/calculator-service/graphql-schema";
// Defaults
// TODO: force future values so no need for defaults?
export const DEFAULT_FUTURE_INCOME_GROWTH = 5;
export const DEFAULT_FUTURE_INFLATION_RATE = 8;
export const DEFAULT_FUTURE_REPAYMENT_THRESHOLD_GROWTH = 3.5;
export const DEFAULT_FUTURE_BASE_INTEREST_RATE = 6;
export const DEFAULT_FUTURE_INCOMES = [];
export const DEFAULT_FUTURE_OVERRIDES = [];
export const DEFAULT_FUTURE_INFLATION_RATES = [];
// Limits
export const MIN_GRADUATION_YEAR = 1998;
export const MAX_MONETARY_AMOUNT = 500000;
export const MAX_MONETARY_AMOUNT_SMALL = 50000;
export const MAX_PERCENTAGE_AMOUNT = 100;
export const MAX_FUTURE_INCOMES_COUNT = 15;
export const MAX_FUTURE_OVERRIDES_COUNT = 15;
export const MAX_VOLUNTARY_PAYMENTS_COUNT = 5;
// Types from GraphQL Schema
export const PLAN_TYPES = Object.keys(PlanType);
