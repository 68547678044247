import { createDate } from "@slrc/shared/utils";
import { getYear, max } from "date-fns";
import { createLoanSupports } from "./helpers/create-loan-supports";
import { DEFAULT_FUTURE_INCOME_GROWTH, DEFAULT_FUTURE_REPAYMENT_THRESHOLD_GROWTH, DEFAULT_FUTURE_INFLATION_RATE, DEFAULT_FUTURE_OVERRIDES, DEFAULT_FUTURE_BASE_INTEREST_RATE, DEFAULT_FUTURE_INCOMES, DEFAULT_FUTURE_INFLATION_RATES } from "@slrc/calculator-service/constants";
import { updateLoanCalculationSummary } from "./summary/update-loan-calculation-summary";
import { calculateSummaryForLoanMonth } from "./summary/calculate-summary-for-loan-month";
import { calculateSummaryForCalculation } from "./summary/calculate-summary-for-calculation";
import { modifyAllFloatsInCalculation } from "./helpers/modify-all-floats-in-calculation";
import { updateLoanSupportWhenEndOfYear } from "./helpers/update-loan-support-when-end-of-year";
import { updateLoanCalculationWhenLoanPaidOff } from "./summary/update-loan-calculation-when-loan-paid-off";
import { allLoanCalculationsFinished, isLoanPaidOff, isLoanCalculationDone, shouldTerminate } from "./helpers/checks";
import { createLoanCalculations } from "./helpers/create-loan-calculations";
import { getRepaymentPeriodMonths } from "./dates/get-repayment-period-months";
import { updateLoanSupportsOtherLoansWhenLoanCalcDone } from "./helpers/update-loan-supports-other-loans-when-loan-calc-done";
import { clone } from "ramda";
export const calculateForInput = (input)=>{
    const { income , loans , assumptions: { futureIncomes =DEFAULT_FUTURE_INCOMES , futureIncomeGrowth =DEFAULT_FUTURE_INCOME_GROWTH , futureRepaymentThresholdGrowth =DEFAULT_FUTURE_REPAYMENT_THRESHOLD_GROWTH , futureBaseInterestRate =DEFAULT_FUTURE_BASE_INTEREST_RATE , futureInflationRate =DEFAULT_FUTURE_INFLATION_RATE , futureInflationRates =DEFAULT_FUTURE_INFLATION_RATES  }  } = input;
    const from = createDate(new Date());
    const loanSupports = createLoanSupports(income, loans, from);
    const maxWriteOffDate = max(Object.values(loanSupports).map((l)=>l.writeOffDate));
    const repaymentPeriodMonths = getRepaymentPeriodMonths(from, maxWriteOffDate);
    const loanCalculations = createLoanCalculations(from, loanSupports, loans);
    // ===========================================================
    monthsLoop: for (const [, currentMonth] of repaymentPeriodMonths.entries()){
        for (const [loanIndex, loan] of loans.entries()){
            const loanCalculation = loanCalculations[loanIndex];
            const loanSupport = loanSupports[loan.plan];
            const { futureOverrides =DEFAULT_FUTURE_OVERRIDES  } = loan;
            // If all loans have finished calculations break out of the monthsLoop
            // in order to stop all calculations
            if (allLoanCalculationsFinished(loanSupports)) {
                break monthsLoop;
            }
            // =======================================================
            // If this particular calculation is done then skip the remaining iterations
            if (loanSupport.calculationDone) continue;
            // =======================================================
            // Calculate interest, repayments etc. for the month
            loanSupport.months.push({
                summary: calculateSummaryForLoanMonth(currentMonth, loan, loanSupport, futureBaseInterestRate, futureOverrides)
            });
            // =======================================================
            // Check if we should terminate because we have reached the end of the year
            // or because the loan calculation is done
            if (shouldTerminate(currentMonth, loanSupport.currentDebt, loanSupport.writeOffDate)) {
                loanCalculation.years.push({
                    months: clone(loanSupport.months),
                    summary: {
                        ...loanSupport.yearSummary
                    }
                });
                if (isLoanCalculationDone(currentMonth, loanSupport.currentDebt, loanSupport.writeOffDate)) {
                    updateLoanCalculationSummary(loanCalculation);
                    updateLoanSupportsOtherLoansWhenLoanCalcDone(loan.plan, loanSupports);
                    loanSupport.calculationDone = true;
                    if (isLoanPaidOff(loanSupport.currentDebt)) {
                        updateLoanCalculationWhenLoanPaidOff(from, currentMonth, loanCalculation);
                    }
                }
                // Reset and update support values
                updateLoanSupportWhenEndOfYear(getYear(currentMonth), getYear(from), loanSupport, futureIncomes, futureIncomeGrowth, futureRepaymentThresholdGrowth, futureInflationRates, futureInflationRate);
            }
        }
    }
    // ===========================================================
    const calculation = {
        summary: calculateSummaryForCalculation(loans, loanCalculations),
        loanCalculations: loanCalculations
    };
    // Set all floats to 1 d.p before returning the calculation
    const modifiedCalculation = modifyAllFloatsInCalculation(calculation);
    return modifiedCalculation;
};
