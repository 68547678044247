import { OutcomeType } from "@slrc/calculator-service/graphql-schema";
import { YEAR_MONTH_FORMAT } from "@slrc/shared/utils";
import { differenceInCalendarMonths, format } from "date-fns";
import { createMonetaryAmount } from "./create-monetary-amount";
import { getMaxYearsForPlan } from "../dates/get-write-off-date-for-loan";
export const createLoanCalculations = (from, loanSupports, loans)=>{
    const loanCalculations = loans.map((loan)=>{
        const loanSupport = loanSupports[loan.plan];
        return {
            summary: {
                plan: loan.plan,
                outcome: OutcomeType.WRITTEN_OFF,
                from: format(from, YEAR_MONTH_FORMAT),
                to: format(loanSupport.writeOffDate, YEAR_MONTH_FORMAT),
                durationInYears: differenceInCalendarMonths(loanSupport.writeOffDate, from) / 12.0,
                maxDurationInYears: getMaxYearsForPlan(loan.plan),
                repaymentStart: format(loanSupport.repaymentStartDate, YEAR_MONTH_FORMAT),
                lifetimeInterestAdded: createMonetaryAmount(0, 0),
                lifetimeMandatoryPaymentsMade: createMonetaryAmount(0, 0),
                lifetimeVoluntaryPaymentsMade: createMonetaryAmount(0, 0),
                remainingDebt: createMonetaryAmount(0, 0)
            },
            years: []
        };
    });
    return loanCalculations;
};
