import {
  FullBleed,
  Ad,
  MobileOnlyAd,
  Quote,
  Example,
  ArticleAd,
} from '@slrc/shared/ui';
import {
  LatestRepaymentThresholds,
  LifetimeIncomeGroups,
  RepaymentThresholdExample,
  RepaymentLifetimeGroupsGraphArticle,
  MultipleLoansIllustration,
  ArticleAnchorHeading,
  LatestRepaymentDetails,
  EachCountryOverview,
} from '@slrc/slrc-web/features/articles';
import ImageBase from 'next/image';
import styled from 'styled-components';

const NextImage = ({ src, alt }) => (
  <Image src={src} alt={alt} width={900} height={490} />
);

const Image = styled(ImageBase)`
  object-fit: contain;
  object-position: center;
`;

export const mdxComponents = {
  FullBleed,
  Ad,
  MobileOnlyAd,
  Quote,
  LifetimeIncomeGroups,
  img: NextImage,
  ArticleAd,
  LatestRepaymentThresholds,
  RepaymentThresholdExample,
  RepaymentLifetimeGroupsGraphArticle,
  MultipleLoansIllustration,
  ArticleAnchorHeading,
  LatestRepaymentDetails,
  Example,
  EachCountryOverview,
};
