import { YEAR_MONTH_DAY_FORMAT } from "@slrc/shared/utils";
import { format } from "date-fns";
export const UK_PLAN3_REPAYMENT_THRESHOLDS = [
    {
        from: format(new Date("2016-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2100-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 21000,
        repaymentRate: 6
    }
];
