import { sumNestedPropertiesInArrayOfObjects } from "@slrc/shared/utils";
import { createMonetaryAmount } from "../helpers/create-monetary-amount";
export const updateLoanCalculationSummary = (loanCalculation)=>{
    // Interest
    const lifetimeInterestAddedNominal = sumNestedPropertiesInArrayOfObjects(loanCalculation.years, [
        "summary",
        "interestAddedInYear",
        "nominal"
    ]);
    const lifetimeInterestAddedReal = sumNestedPropertiesInArrayOfObjects(loanCalculation.years, [
        "summary",
        "interestAddedInYear",
        "real"
    ]);
    // Mandatory
    const lifetimeMandatoryPaymentsMadeNominal = sumNestedPropertiesInArrayOfObjects(loanCalculation.years, [
        "summary",
        "mandatoryPaymentsMadeInYear",
        "nominal"
    ]);
    const lifetimeMandatoryPaymentsMadeReal = sumNestedPropertiesInArrayOfObjects(loanCalculation.years, [
        "summary",
        "mandatoryPaymentsMadeInYear",
        "real"
    ]);
    // Voluntary
    const lifetimeVoluntaryPaymentsMadeNominal = sumNestedPropertiesInArrayOfObjects(loanCalculation.years, [
        "summary",
        "voluntaryPaymentsMadeInYear",
        "nominal"
    ]);
    const lifetimeVoluntaryPaymentsMadeReal = sumNestedPropertiesInArrayOfObjects(loanCalculation.years, [
        "summary",
        "voluntaryPaymentsMadeInYear",
        "real"
    ]);
    // Debt
    const remainingDebtNominal = loanCalculation.years[loanCalculation.years.length - 1].summary.remainingDebtInYear.nominal;
    const remainingDebtReal = loanCalculation.years[loanCalculation.years.length - 1].summary.remainingDebtInYear.real;
    // Summary
    loanCalculation.summary = {
        ...loanCalculation.summary,
        lifetimeInterestAdded: createMonetaryAmount(lifetimeInterestAddedNominal, lifetimeInterestAddedReal),
        lifetimeMandatoryPaymentsMade: createMonetaryAmount(lifetimeMandatoryPaymentsMadeNominal, lifetimeMandatoryPaymentsMadeReal),
        lifetimeVoluntaryPaymentsMade: createMonetaryAmount(lifetimeVoluntaryPaymentsMadeNominal, lifetimeVoluntaryPaymentsMadeReal),
        remainingDebt: createMonetaryAmount(remainingDebtNominal, remainingDebtReal)
    };
};
