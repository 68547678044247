import { isAfter, isSameMonth, startOfMonth } from "date-fns";
import { createDate } from "@slrc/shared/utils";
// TODO: cleanup below two functions and rename stuff
const filterVoluntaryPayments = (voluntaryPayments)=>{
    return voluntaryPayments === null || voluntaryPayments === void 0 ? void 0 : voluntaryPayments.filter((v)=>{
        const fromOrOnDate = createDate(startOfMonth(new Date(v.voluntaryPaymentType === "monthly" ? v.from : v.on)));
        const today = createDate(startOfMonth(new Date()));
        return isSameMonth(fromOrOnDate, today) || isAfter(fromOrOnDate, today);
    });
};
export const removeFutureIncomesAndOverpaymentsWithOldDates = (cookieFormValues)=>{
    var _cookieFormValues_futureIncomes, _cookieFormValues_otherLoans, _cookieFormValues_otherLoans1, _cookieFormValues_otherLoans2, _cookieFormValues_otherLoans3, _cookieFormValues_otherLoans4, _cookieFormValues_otherLoans5, _cookieFormValues_otherLoans6, _cookieFormValues_otherLoans7, _cookieFormValues_otherLoans8, _cookieFormValues_otherLoans9, _cookieFormValues_otherLoans10, _cookieFormValues_otherLoans11, _cookieFormValues_otherLoans12, _cookieFormValues_otherLoans13, _cookieFormValues_otherLoans14;
    return {
        ...cookieFormValues,
        futureIncomes: (_cookieFormValues_futureIncomes = cookieFormValues.futureIncomes) === null || _cookieFormValues_futureIncomes === void 0 ? void 0 : _cookieFormValues_futureIncomes.filter((f)=>f.year >= createDate(startOfMonth(new Date())).getFullYear()),
        voluntaryPayments: filterVoluntaryPayments(cookieFormValues.voluntaryPayments),
        otherLoans: cookieFormValues.otherLoans ? {
            ...((_cookieFormValues_otherLoans = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans === void 0 ? void 0 : _cookieFormValues_otherLoans.UK_PLAN1) && {
                UK_PLAN1: {
                    ...(_cookieFormValues_otherLoans1 = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans1 === void 0 ? void 0 : _cookieFormValues_otherLoans1.UK_PLAN1,
                    voluntaryPayments: filterVoluntaryPayments((_cookieFormValues_otherLoans2 = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans2 === void 0 ? void 0 : _cookieFormValues_otherLoans2.UK_PLAN1.voluntaryPayments)
                }
            },
            ...((_cookieFormValues_otherLoans3 = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans3 === void 0 ? void 0 : _cookieFormValues_otherLoans3.UK_PLAN2) && {
                UK_PLAN2: {
                    ...(_cookieFormValues_otherLoans4 = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans4 === void 0 ? void 0 : _cookieFormValues_otherLoans4.UK_PLAN2,
                    voluntaryPayments: filterVoluntaryPayments((_cookieFormValues_otherLoans5 = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans5 === void 0 ? void 0 : _cookieFormValues_otherLoans5.UK_PLAN2.voluntaryPayments)
                }
            },
            ...((_cookieFormValues_otherLoans6 = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans6 === void 0 ? void 0 : _cookieFormValues_otherLoans6.UK_PLAN3) && {
                UK_PLAN3: {
                    ...(_cookieFormValues_otherLoans7 = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans7 === void 0 ? void 0 : _cookieFormValues_otherLoans7.UK_PLAN3,
                    voluntaryPayments: filterVoluntaryPayments((_cookieFormValues_otherLoans8 = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans8 === void 0 ? void 0 : _cookieFormValues_otherLoans8.UK_PLAN3.voluntaryPayments)
                }
            },
            ...((_cookieFormValues_otherLoans9 = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans9 === void 0 ? void 0 : _cookieFormValues_otherLoans9.UK_PLAN4) && {
                UK_PLAN4: {
                    ...(_cookieFormValues_otherLoans10 = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans10 === void 0 ? void 0 : _cookieFormValues_otherLoans10.UK_PLAN4,
                    voluntaryPayments: filterVoluntaryPayments((_cookieFormValues_otherLoans11 = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans11 === void 0 ? void 0 : _cookieFormValues_otherLoans11.UK_PLAN4.voluntaryPayments)
                }
            },
            ...((_cookieFormValues_otherLoans12 = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans12 === void 0 ? void 0 : _cookieFormValues_otherLoans12.UK_PLAN5) && {
                UK_PLAN5: {
                    ...(_cookieFormValues_otherLoans13 = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans13 === void 0 ? void 0 : _cookieFormValues_otherLoans13.UK_PLAN5,
                    voluntaryPayments: filterVoluntaryPayments((_cookieFormValues_otherLoans14 = cookieFormValues.otherLoans) === null || _cookieFormValues_otherLoans14 === void 0 ? void 0 : _cookieFormValues_otherLoans14.UK_PLAN5.voluntaryPayments)
                }
            }
        } : undefined
    };
};
