
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '@fontsource/poppins';
import 'nprogress/nprogress.css';
import { AppProps } from 'next/app';
import {
  BaseStyle,
  Text,
  useFathomPageView,
  useGaPageView,
  useNprogress,
} from '@slrc/shared/ui';
import '../i18n';
import { SEO } from '../config/seo';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { useState } from 'react';
import { DefaultSeo } from 'next-seo';
import { MDXProvider } from '@mdx-js/react';
import { mdxComponents } from '../config/mdx-components';
import { MainLayout } from '@slrc/slrc-web/features/layout';
import * as Sentry from '@sentry/nextjs';
import { createGlobalStyle } from 'styled-components';
import { AppStyle } from '../style/app';
import Script from 'next/script';
import { SLRC_WEB_IS_PRODUCTION } from '@slrc/env';
import { reactQueryClientConfig } from '@slrc/slrc-web/data-fetching';
import { Provider } from 'react-redux';
import { store } from '@slrc/slrc-web/features/calculator';
import { Analytics } from '@vercel/analytics/react';
import { SupabaseProvider } from '@slrc/slrc-web/data-fetching';

const GlobalStyle = createGlobalStyle`
  ${BaseStyle}
  ${AppStyle}
`;

function CustomApp({ Component, pageProps, router }: AppProps) {
  const [queryClient] = useState(() => new QueryClient(reactQueryClientConfig));
  useNprogress();
  useGaPageView(SLRC_WEB_IS_PRODUCTION);
  useFathomPageView(SLRC_WEB_IS_PRODUCTION);

  return (
    <>
      {/* TODO: shift to _document once it actually works in _document */}
      {SLRC_WEB_IS_PRODUCTION && (
        <>
          {/* Fathom Analytics gets loaded by hook useFathomPageView() */}

          {/* Google Analytics - do I need this for Ads? TODO: remove if possible */}
          <Script
            defer
            strategy="afterInteractive"
            src="https://www.googletagmanager.com/gtag/js?id=G-PPRZYZEKBV"
          ></Script>
          <Script defer strategy="afterInteractive" id="an-g">{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-PPRZYZEKBV');
          `}</Script>

          {/* Google Ads */}
          <Script
            defer
            strategy="afterInteractive"
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9272279680176620"
          ></Script>
        </>
      )}
      <Sentry.ErrorBoundary
        // TODO: need page for this
        fallback={<Text>An error has occurred</Text>}
        showDialog
      >
        {/* TODO: add themes */}
        {/* <ThemeProvider enableSystem> */}
        <SupabaseProvider>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <MDXProvider components={mdxComponents}>
                <GlobalStyle />
                <DefaultSeo {...SEO()} />
                <Provider store={store}>
                  <MainLayout pathname={router.asPath}>
                    <Component {...pageProps} />
                    <Analytics />
                  </MainLayout>
                </Provider>
              </MDXProvider>
            </Hydrate>
          </QueryClientProvider>
        </SupabaseProvider>
      </Sentry.ErrorBoundary>
    </>
  );
}

const __Page_Next_Translate__ = CustomApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  