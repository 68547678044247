import { parseStringDate } from "@slrc/shared/utils";
import { getYear, isWithinInterval } from "date-fns";
import { INTEREST_RATES } from "./interest-rates";
export const getInterestRateForPlanAndDateFromRepository = (plan, date, futureBaseInterestRate, futureOverrides)=>{
    const planInterestRates = INTEREST_RATES[plan];
    const interestRate = planInterestRates.find((param)=>{
        let { from , to  } = param;
        return isWithinInterval(date, {
            start: parseStringDate(from),
            end: parseStringDate(to)
        });
    });
    if (interestRate) {
        return interestRate;
    }
    if (futureOverrides.length) {
        const year = getYear(date);
        const futureBaseInterestRateInYear = futureOverrides.find((p)=>p.year === year);
        if (futureBaseInterestRateInYear && futureBaseInterestRateInYear.baseInterestRate !== undefined) {
            return {
                ...planInterestRates[planInterestRates.length - 1],
                base: futureBaseInterestRateInYear.baseInterestRate
            };
        }
    }
    return {
        ...planInterestRates[planInterestRates.length - 1],
        base: futureBaseInterestRate
    };
};
