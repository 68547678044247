import { parseStringDate } from "@slrc/shared/utils";
import { isWithinInterval } from "date-fns";
export const calculateVoluntaryPaymentToMake = (date, extraMonthlyRepayments)=>{
    const voluntaryPayment = extraMonthlyRepayments.find((param)=>{
        let { from , to  } = param;
        return isWithinInterval(date, {
            start: parseStringDate("".concat(from, "-01")),
            end: parseStringDate("".concat(to, "-01"))
        });
    });
    if (voluntaryPayment) {
        return voluntaryPayment.amount;
    }
    return 0;
};
