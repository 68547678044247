// =============================================================
// =============================================================
// FROM PIPELINES
// =============================================================
// =============================================================
//      SHARED
// =============================================================
export const { AWS_ACCESS_KEY_ID  } = process.env;
export const { AWS_SECRET_ACCESS_KEY  } = process.env;
export const { AWS_DYNAMODB_ENDPOINT  } = process.env;
export const { AWS_SNS_ENDPOINT  } = process.env;
export const { AWS_S3_ENDPOINT  } = process.env;
export const { RAPID_API_KEY ="rapid-api-key"  } = process.env;
export const { RAPID_API_PROXY_SECRET ="rapid-api-proxy-secret"  } = process.env;
export const { CONTENTFUL_SPACE_ID  } = process.env;
export const { CONTENTFUL_ACCESS_KEY  } = process.env;
export const { CONTENTFUL_HOST ="cdn.contentful.com"  } = process.env;
export const { SLRC_SERVICE_API_KEY ="slrc-service-api-key"  } = process.env;
//      SLRC-WEB
// =============================================================
const VERCEL_URL = process.env["VERCEL_URL"];
export const NEXT_PUBLIC_SLRC_WEB_URL = VERCEL_URL ? "https://".concat(VERCEL_URL) : process.env["NEXT_PUBLIC_SLRC_WEB_URL"];
export const { NEXT_PUBLIC_VERCEL_ENV  } = process.env;
export const NEXT_PUBLIC_SLRC_WEB_APP_ENV = NEXT_PUBLIC_VERCEL_ENV === "production" ? "production" : process.env["NEXT_PUBLIC_SLRC_WEB_APP_ENV"] || NEXT_PUBLIC_VERCEL_ENV;
export const { NEXT_TRANSLATE_PATH  } = process.env;
export const { SLRC_WEB_SENTRY_TRACES_SAMPLE_RATE =0  } = process.env;
export const { CALCULATOR_SERVICE_URL  } = process.env;
export const { SLRC_SERVICE_URL  } = process.env;
// comes from next.config.js
export const SLRC_WEB_BUILD_ID = process.env["NEXT_PUBLIC_BUILD_ID"] || "x";
// generated from NEXT_PUBLIC_SLRC_WEB_APP_ENV
export const SLRC_WEB_IS_PRODUCTION = NEXT_PUBLIC_SLRC_WEB_APP_ENV === "production";
//    Supabase
export const { NEXT_PUBLIC_SITE_URL  } = process.env;
export const { NEXT_PUBLIC_SUPABASE_URL  } = process.env;
export const { SUPABASE_SERVICE_ROLE_KEY  } = process.env;
export const { NEXT_PUBLIC_SUPABASE_ANON_KEY  } = process.env;
//    Stripe
export const { STRIPE_WEBHOOK_SECRET  } = process.env;
export const { NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY  } = process.env;
export const { STRIPE_SECRET_KEY  } = process.env;
// =============================================================
// =============================================================
// FROM SERVERLESS YMLS
// =============================================================
// =============================================================
//      CALCULATOR-SERVICE
// =============================================================
export const { CALCULATOR_SERVICE_APP_ENV  } = process.env;
export const { CALCULATOR_SERVICE_IS_PRODUCTION  } = process.env;
export const { CALCULATOR_SERVICE_SENTRY_TRACES_SAMPLE_RATE  } = process.env;
export const { CALCULATOR_SERVICE_EVENT_TOPIC_NAME  } = process.env;
export const { CALCULATOR_SERVICE_EVENT_TOPIC_ARN  } = process.env;
export const { CALCULATOR_SERVICE_IS_LOCAL  } = process.env;
//      SLRC-SERVICE
// =============================================================
export const { SLRC_SERVICE_APP_ENV  } = process.env;
export const { SLRC_SERVICE_IS_PRODUCTION  } = process.env;
export const { SLRC_SERVICE_SENTRY_TRACES_SAMPLE_RATE  } = process.env;
export const { SLRC_SERVICE_DYNAMODB_TABLE_ARTICLES_HITS  } = process.env;
export const { SLRC_SERVICE_COMPLETED_CALCULATIONS_BUCKET_NAME  } = process.env;
export const { SLRC_SERVICE_DYNAMODB_TABLE_INSIGHTS  } = process.env;
