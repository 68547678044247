import { createMonetaryAmount } from "../helpers/create-monetary-amount";
import { calculateInterestToAdd } from "../interest/calculate-interest-to-add";
import { calculateMandatoryPaymentToMake, getRepaymentThresholdForPlanAndDate } from "../repayment/calculate-mandatory-payment-to-make";
import { calculateVoluntaryPaymentToMake } from "../repayment/calculate-voluntary-payment-to-make";
import { format } from "date-fns";
import { YEAR_MONTH_FORMAT } from "@slrc/shared/utils";
const updateInterest = (loanSupport, interestToAdd)=>{
    loanSupport.yearSummary.interestAddedInYear.nominal += interestToAdd;
    loanSupport.yearSummary.interestAddedInYear.real = loanSupport.yearSummary.interestAddedInYear.nominal * loanSupport.inflationMultiplierForYear;
    loanSupport.yearSummary.interestAddedCumulative.nominal += interestToAdd;
    loanSupport.yearSummary.interestAddedCumulative.real = loanSupport.yearSummary.interestAddedCumulative.nominal * loanSupport.inflationMultiplierForYear;
};
const updateMandatory = (loanSupport, mandatoryPaymentToMake)=>{
    loanSupport.yearSummary.mandatoryPaymentsMadeInYear.nominal += mandatoryPaymentToMake;
    loanSupport.yearSummary.mandatoryPaymentsMadeInYear.real = loanSupport.yearSummary.mandatoryPaymentsMadeInYear.nominal * loanSupport.inflationMultiplierForYear;
    loanSupport.yearSummary.mandatoryPaymentsMadeCumulative.nominal += mandatoryPaymentToMake;
    loanSupport.yearSummary.mandatoryPaymentsMadeCumulative.real = loanSupport.yearSummary.mandatoryPaymentsMadeCumulative.nominal * loanSupport.inflationMultiplierForYear;
};
const updateVoluntary = (loanSupport, voluntaryPaymentToMake)=>{
    loanSupport.yearSummary.voluntaryPaymentsMadeInYear.nominal += voluntaryPaymentToMake;
    loanSupport.yearSummary.voluntaryPaymentsMadeInYear.real = loanSupport.yearSummary.voluntaryPaymentsMadeInYear.nominal * loanSupport.inflationMultiplierForYear;
    loanSupport.yearSummary.voluntaryPaymentsMadeCumulative.nominal += voluntaryPaymentToMake;
    loanSupport.yearSummary.voluntaryPaymentsMadeCumulative.real = loanSupport.yearSummary.voluntaryPaymentsMadeCumulative.nominal * loanSupport.inflationMultiplierForYear;
};
const updateDebt = (loanSupport, debtToAdd)=>{
    loanSupport.currentDebt += debtToAdd;
    loanSupport.yearSummary.remainingDebtInYear.nominal = Math.max(0, loanSupport.currentDebt);
    loanSupport.yearSummary.remainingDebtInYear.real = Math.max(0, loanSupport.currentDebt * loanSupport.inflationMultiplierForYear);
};
const updateAverageInterestRateAndRepaymentThreshold = (loanSupport, interestRatePercentage, repaymentThreshold)=>{
    // TODO: make running average
    loanSupport.yearSummary.averageInterestRate = interestRatePercentage;
    loanSupport.yearSummary.averageRepaymentThreshold = createMonetaryAmount(repaymentThreshold, repaymentThreshold * loanSupport.inflationMultiplierForYear);
};
export const calculateSummaryForLoanMonth = (date, loan, loanSupport, futureBaseInterestRate, futureOverrides)=>{
    // Interest
    const { interestToAdd , interestRatePercentage  } = calculateInterestToAdd(loan.plan, date, loanSupport.currentIncome, loanSupport.currentDebt, loanSupport.repaymentThresholdMultiplierForYear, futureBaseInterestRate, futureOverrides);
    updateInterest(loanSupport, interestToAdd);
    // Mandatory Payment
    const mandatoryPaymentToMake = calculateMandatoryPaymentToMake(loan.plan, date, loanSupport.repaymentStartDate, loanSupport.currentIncome, loanSupport.otherLoansNotYetPaidOrWrittenOff, loanSupport.repaymentThresholdMultiplierForYear, futureOverrides);
    updateMandatory(loanSupport, mandatoryPaymentToMake);
    // Voluntary Payment
    const voluntaryPaymentToMake = calculateVoluntaryPaymentToMake(date, loan.extraMonthlyRepayments || []);
    updateVoluntary(loanSupport, voluntaryPaymentToMake);
    // Debt
    const debtToAdd = interestToAdd - mandatoryPaymentToMake - voluntaryPaymentToMake;
    updateDebt(loanSupport, debtToAdd);
    const repaymentThreshold = getRepaymentThresholdForPlanAndDate(loan.plan, date, loanSupport.repaymentThresholdMultiplierForYear, futureOverrides).min;
    updateAverageInterestRateAndRepaymentThreshold(loanSupport, interestRatePercentage, repaymentThreshold);
    // Summary
    return {
        month: format(date, YEAR_MONTH_FORMAT),
        interestRate: interestRatePercentage,
        inflationRate: loanSupport.inflationRateForYear,
        repaymentThreshold: createMonetaryAmount(repaymentThreshold, repaymentThreshold * loanSupport.inflationMultiplierForYear),
        interestAddedInMonth: createMonetaryAmount(interestToAdd, interestToAdd * loanSupport.inflationMultiplierForYear),
        mandatoryPaymentMadeInMonth: createMonetaryAmount(mandatoryPaymentToMake, mandatoryPaymentToMake * loanSupport.inflationMultiplierForYear),
        voluntaryPaymentMadeInMonth: createMonetaryAmount(voluntaryPaymentToMake, voluntaryPaymentToMake * loanSupport.inflationMultiplierForYear),
        remainingDebtInMonth: createMonetaryAmount(Math.max(0, loanSupport.currentDebt), Math.max(0, loanSupport.currentDebt * loanSupport.inflationMultiplierForYear))
    };
};
