import { parseStringDate, sumNestedPropertiesInArrayOfObjects, YEAR_MONTH_FORMAT } from "@slrc/shared/utils";
import { min, max, format, differenceInCalendarMonths } from "date-fns";
import { createMonetaryAmount } from "../helpers/create-monetary-amount";
export const calculateSummaryForCalculation = (loans, loanCalculations)=>{
    const firstRepaymentStart = min(loanCalculations.map((loanCalculation)=>parseStringDate(loanCalculation.summary.repaymentStart)));
    const from = min(loanCalculations.map((loanCalculation)=>parseStringDate(loanCalculation.summary.from)));
    const to = max(loanCalculations.map((loanCalculation)=>parseStringDate(loanCalculation.summary.to)));
    // Interest
    const totalInterestAddedNominal = sumNestedPropertiesInArrayOfObjects(loanCalculations, [
        "summary",
        "lifetimeInterestAdded",
        "nominal"
    ]);
    const totalInterestAddedReal = sumNestedPropertiesInArrayOfObjects(loanCalculations, [
        "summary",
        "lifetimeInterestAdded",
        "real"
    ]);
    // Mandatory
    const totalMandatoryPaymentsMadeNominal = sumNestedPropertiesInArrayOfObjects(loanCalculations, [
        "summary",
        "lifetimeMandatoryPaymentsMade",
        "nominal"
    ]);
    const totalMandatoryPaymentsMadeReal = sumNestedPropertiesInArrayOfObjects(loanCalculations, [
        "summary",
        "lifetimeMandatoryPaymentsMade",
        "real"
    ]);
    // Voluntary
    const totalVoluntaryPaymentsMadeNominal = sumNestedPropertiesInArrayOfObjects(loanCalculations, [
        "summary",
        "lifetimeVoluntaryPaymentsMade",
        "nominal"
    ]);
    const totalVoluntaryPaymentsMadeReal = sumNestedPropertiesInArrayOfObjects(loanCalculations, [
        "summary",
        "lifetimeVoluntaryPaymentsMade",
        "real"
    ]);
    // Debt
    const totalRemainingDebtNominal = sumNestedPropertiesInArrayOfObjects(loanCalculations, [
        "summary",
        "remainingDebt",
        "nominal"
    ]);
    const totalRemainingDebtReal = sumNestedPropertiesInArrayOfObjects(loanCalculations, [
        "summary",
        "remainingDebt",
        "real"
    ]);
    // Debt at start
    const totalDebtAtStartNominal = sumNestedPropertiesInArrayOfObjects(loans, [
        "amount"
    ]);
    return {
        firstRepaymentStart: format(firstRepaymentStart, YEAR_MONTH_FORMAT),
        from: format(from, YEAR_MONTH_FORMAT),
        to: format(to, YEAR_MONTH_FORMAT),
        totalDurationInYears: differenceInCalendarMonths(to, from) / 12.0,
        totalInterestAdded: createMonetaryAmount(totalInterestAddedNominal, totalInterestAddedReal),
        totalMandatoryPaymentsMade: createMonetaryAmount(totalMandatoryPaymentsMadeNominal, totalMandatoryPaymentsMadeReal),
        totalVoluntaryPaymentsMade: createMonetaryAmount(totalVoluntaryPaymentsMadeNominal, totalVoluntaryPaymentsMadeReal),
        totalRemainingDebt: createMonetaryAmount(totalRemainingDebtNominal, totalRemainingDebtReal),
        totalDebtAtStart: createMonetaryAmount(totalDebtAtStartNominal, totalDebtAtStartNominal)
    };
};
