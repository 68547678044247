import { useEffect, useState } from "react";
import { SM_UPPER_BOUND, MD_UPPER_BOUND, LG_UPPER_BOUND } from "../styles";
import { useWindowSize } from "./use-window-size.hook";
export function useBreakpoint() {
    const { width  } = useWindowSize();
    const [breakpoints, setBreakpoints] = useState({});
    useEffect(()=>{
        if (window && document) {
            const bps = {};
            const SM_UPPER_BOUND_PIXELS = SM_UPPER_BOUND * parseInt(window.getComputedStyle(document.documentElement).fontSize);
            const MD_UPPER_BOUND_PIXELS = MD_UPPER_BOUND * parseInt(window.getComputedStyle(document.documentElement).fontSize);
            const LG_UPPER_BOUND_PIXELS = LG_UPPER_BOUND * parseInt(window.getComputedStyle(document.documentElement).fontSize);
            if (window.innerWidth >= 0) {
                bps.SM = true;
            }
            if (window.innerWidth >= SM_UPPER_BOUND_PIXELS) {
                bps.MD = true;
            }
            if (window.innerWidth >= MD_UPPER_BOUND_PIXELS) {
                bps.LG = true;
            }
            if (window.innerWidth >= LG_UPPER_BOUND_PIXELS) {
                bps.XL = true;
            }
            setBreakpoints(bps);
        }
    }, [
        width
    ]);
    return breakpoints;
}
