import { useCallback, useState } from "react";
import { useEventListener } from "./use-event-listener.hook";
import { useIsomorphicLayoutEffect } from "./use-isomorphic-layout-effect";
export function useElementSize() {
    // Mutable values like 'ref.current' aren't valid dependencies
    // because mutating them doesn't re-render the component.
    // Instead, we use a state as a ref to be reactive.
    const [ref, setRef] = useState(null);
    const [size, setSize] = useState({
        width: 0,
        height: 0
    });
    // Prevent too many rendering using useCallback
    const handleSize = useCallback(()=>{
        setSize({
            width: (ref === null || ref === void 0 ? void 0 : ref.offsetWidth) || 0,
            height: (ref === null || ref === void 0 ? void 0 : ref.offsetHeight) || 0
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        ref === null || ref === void 0 ? void 0 : ref.offsetHeight,
        ref === null || ref === void 0 ? void 0 : ref.offsetWidth
    ]);
    useEventListener("resize", handleSize);
    useIsomorphicLayoutEffect(()=>{
        handleSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        ref === null || ref === void 0 ? void 0 : ref.offsetHeight,
        ref === null || ref === void 0 ? void 0 : ref.offsetWidth
    ]);
    return [
        setRef,
        size
    ];
}
