import { CALCULATOR_SERVICE_EVENT_TOPIC_ARN, CALCULATOR_SERVICE_IS_LOCAL } from "@slrc/env";
import { snsClient } from "@slrc/calculator-service/infrastructure";
import { PublishCommand } from "@aws-sdk/client-sns";
export async function publishSNSEvent(eventType, message) {
    if (CALCULATOR_SERVICE_IS_LOCAL === "false") {
        try {
            const data = await snsClient.send(new PublishCommand({
                TopicArn: CALCULATOR_SERVICE_EVENT_TOPIC_ARN,
                Subject: eventType,
                Message: JSON.stringify(message)
            }));
            console.log("Message published: ".concat(data === null || data === void 0 ? void 0 : data.MessageId));
        } catch (err) {
            console.log(err);
        }
    }
}
