import { keys, path, reduce } from "ramda";
export const funcForEachRecurse = (obj, func)=>{
    const ks = keys(obj);
    ks.forEach((k)=>{
        if (typeof obj[k] === "object") {
            funcForEachRecurse(obj[k], func);
        }
        func(k, obj[k], obj);
    });
};
export function sumNestedPropertiesInArrayOfObjects(list, p) {
    return reduce((acc, elem)=>{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // Ignore path instantiation is excessively deep warning
        const value = path(p, elem);
        if (value !== undefined) {
            return acc + value;
        }
        return acc;
    }, 0, list);
}
