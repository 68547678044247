import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    const data = _tagged_template_literal([
        "\n  color: ",
        ";\n  border-bottom: ",
        ";\n  font-size: 1rem;\n  margin-bottom: -4px;\n  transition: transform 200ms ease, font-size 200ms ease;\n\n  &:hover {\n    transform: scale(1.025);\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
function _templateObject1() {
    const data = _tagged_template_literal([
        "\n  border-top-left-radius: 16px !important;\n  border-bottom-left-radius: 16px !important;\n  background-color: var(--slrc-colour-grey-50);\n  color: ",
        ";\n  border-right: ",
        ";\n  font-size: 1rem;\n  transition: transform 200ms;\n  border-radius: 0;\n\n  &:hover {\n    transform: scale(1.025);\n  }\n"
    ]);
    _templateObject1 = function() {
        return data;
    };
    return data;
}
import { Button } from "@slrc/shared/ui";
import styled from "styled-components";
export const ButtonLink = styled(Button).withConfig({
    displayName: "ButtonLink",
    componentId: "sc-61fede34-0"
})(_templateObject(), (param)=>{
    let { selected  } = param;
    return selected ? "var(--slrc-colour-primary-600)" : "initial";
}, (param)=>{
    let { selected  } = param;
    return selected ? "4px solid" : "4px solid transparent";
});
export const ButtonLinkMobile = styled(Button).withConfig({
    displayName: "ButtonLink__ButtonLinkMobile",
    componentId: "sc-61fede34-1"
})(_templateObject1(), (param)=>{
    let { selected  } = param;
    return selected ? "var(--slrc-colour-primary-600)" : "initial";
}, (param)=>{
    let { selected  } = param;
    return selected ? "8px solid" : "8px solid transparent";
});
export function isLinkUrlCurrentPath(linkUrl, path) {
    if (path === "/articles/student-loans-explained") {
        if (linkUrl === "/articles/student-loans-explained") {
            return true;
        }
        return false;
    }
    const firstPartOfPath = path.split("/").filter((p)=>p)[0];
    if (linkUrl === "/".concat(firstPartOfPath)) {
        return true;
    }
    return false;
}
