import { useEffect, useState } from "react";
export const useDetectAdblock = ()=>{
    const [adBlockDetected, setAdBlockDetected] = useState(true);
    useEffect(()=>{
        const doFetch = async ()=>{
            // https://github1s.com/gorhill/uBlock/blob/master/docs/tests/hostname-pool.js
            try {
                await fetch("https://googleads.g.doubleclick.net/pagead/id", {
                    method: "HEAD",
                    mode: "no-cors",
                    cache: "no-store"
                });
                setAdBlockDetected(false);
            } catch (e) {
                setAdBlockDetected(true);
            }
        };
        doFetch();
    }, []);
    return adBlockDetected;
};
